
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import styles from '../../../../pages/read-documents/styles/tableResults.module.scss'
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth'
import DownloadIcon from '@mui/icons-material/Download';
import { ITable } from 'shared/components/interfaces';
import Images from 'shared/assets/index';
import { SearchItems } from 'shared/components/paginator/SearchItems';
import { IRespDocuments } from 'shared/models';

interface IHeaderSearchProps {
    downloadExcel?: (() => void),
    optionsTrazact?: ((id: string) => JSX.Element),
    getSectionClassName: () => string,
    isDisabledButtonTraza: boolean | undefined,
    buttonClassName: string
    startIconContent: JSX.Element | undefined,
    props: ITable<any>
    contentSize: number,
    totalElements: number,
    idTable: string | undefined,
    content: IRespDocuments[] | null
}

export const HeaderSearch = ({ buttonClassName, contentSize, downloadExcel, isDisabledButtonTraza, optionsTrazact, props, 
    startIconContent, totalElements, getSectionClassName, idTable, content}: IHeaderSearchProps) => {

    const { width } = useWidth()
    return (
        <Box className={styles.searchContainer}>
            <span className={styles.searchItems}>
                <SearchItems data={content!} placeholder='Buscar' autocomplete={false} />
            </span>
        {
        width > 900 ?
            <section className={styles.contentButton}>
            {
                downloadExcel &&
                <Tooltip title='Exportar Facturas' placement="top" arrow>
                    <IconButton
                        className={styles.buttonExportExcel}
                        data-testid="btn-export"
                        onClick={downloadExcel}
                    >
                    <img className={styles.exportImage} src={Images.iconoDescargar} alt="Exportar a excel" />
                    </IconButton>
                </Tooltip>
                }
            { optionsTrazact && optionsTrazact?.(idTable ?? '') }
            </section>
        : 
        <section className={getSectionClassName()} data-testid='section-buttons-mobil'>
            {
            downloadExcel &&
                <Button onClick={downloadExcel} className={styles.buttons} data-testid="btn-export-mobil" startIcon={<DownloadIcon />}>
                Exportar Facturas
                </Button>
            }
            {
            optionsTrazact &&
                <Button disabled={isDisabledButtonTraza} data-testid="btn-mobil-traza"  className={buttonClassName} startIcon={startIconContent}>
                Asignar
                </Button>
            }
        </section>
        }
        <Box className={`${props.classTotalElemnt ? props.classTotalElemnt : styles.contenedorTotalResultados }`} data-testid='contenedor_para_desktop'>
        <p className={styles.resultadosTotales}>{contentSize}/{totalElements}</p>
        </Box>
    </Box>
    )
}
