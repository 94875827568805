import { Box, ListItemButton } from '@mui/material';
import { TrendingUp, Payment } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { Link, useLocation } from 'react-router-dom';
import { RouterGuard } from 'shared/components/RouteGuard';
import { accesRoles } from '../../models/roles';
import Images from 'shared/assets/index'
import  './styles/_menuList.scss'
import { GET_ELECTRONIC_DOCS } from 'shared/api';
import {cleanLocalStorage, saveSearchHistoryElectronicDocument } from 'shared/utils';

export const MenuList = () => {
    const dispatch = useDispatch<Dispatch>();
	const { selectedIndex } = useSelector((state: RootState) => state.menuDrawer)

    const handleChangeIndex = (index: number) => dispatch.menuDrawer.changeSelectedIndex(index) 

    const handleDocumentActions = () => {
        dispatch.electronicDocuments.cleanState();
        dispatch.searcher.addText('');
        cleanLocalStorage('searchHistoryAutorizationPayments');
    };

    return (
        <Box className='containerOptions'>
            <ListItemButton
                selected={selectedIndex === 0}
                data-testid='item-menu-home'
                onClick={() => handleChangeIndex(0)}
                className='itemDrawer'
            >
                <Link to={'/'} aria-label='btn-home' data-testid='btn-menu-home'>
                    <img src={Images.iconoHome} alt='Home'/>
                    <span>Home</span>
                </Link>
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 1}
                className='itemDrawer'
                onClick={()=>{
                    handleChangeIndex(1);
                    handleDocumentActions();
                    dispatch.electronicDocuments.getElectronicDocuments({states:"", origin: 'MENU'}, `${GET_ELECTRONIC_DOCS}/page`);
                    saveSearchHistoryElectronicDocument({params: {states: "", origin: 'MENU'}, url: `${GET_ELECTRONIC_DOCS}/page`});
                }}
                data-testid='item-menu-consultas'
            >
                <RouterGuard roles={accesRoles.reader}  type="component">
                    <Link to={'/consulta_documentos_electronicos'} aria-label='btn-consultas' data-testid='btn-menu-consultas'>
                        <img src={Images.iconoFacturas} alt='Documento'/>
                        <span>Documentos electrónicos</span>
                    </Link>
                </RouterGuard>
            </ListItemButton>
            <RouterGuard roles={accesRoles.usersSSSA} type="component">
                <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => {
                        handleChangeIndex(2);
                        handleDocumentActions();
                        cleanLocalStorage('searchHistoryElectronicDocument');
                    }}
                    className='itemDrawer'
                    data-testid='item-menu-reportes'
                >
                    <Link to={'/reportes'} aria-label='btn-reportes'data-testid='btn-menu-reportes'>
                        <TrendingUp/>
                        Reportes
                    </Link>
                </ListItemButton>
            </RouterGuard>
            <RouterGuard roles={accesRoles.finance} type="component">
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => {
                        cleanLocalStorage('searchHistoryElectronicDocument');
                        handleDocumentActions();
                    }}
                    className='itemDrawer'
                    data-testid='item-menu-authorization'
                >
                    <Link to={'/autorizaciones'} aria-label='btn-authorization' data-testid='btn-menu-authorization'>
                        <Payment/>
                        Autorizaciones de pago
                    </Link>
                </ListItemButton>
            </RouterGuard>
        </Box>
    )
}
