

export const selectIndex = (value: string) => {
    if (value === 'consulta_documentos_electronicos') {
        return 1;
    }

    if (value === 'autorizaciones') {
        return 3;
    }

    return 0;
}
