import { Models } from "@rematch/core";
import { menuDrawer, linkDocument, linkSupports, electronicDocuments, reports, validateDocuments, reportSingle, searcher, invoiceStates, optionsDropdowns, notifications, consolidateGlosas, comments, collapseState } from "./states";
import { auditDetails } from "./states/auditDetails";
import { invoiceData } from "./states/invoiceData";
import { messageModal } from "./states/messageModal";
import { iframes } from "./states/iframes";
import { prepareInvoiceData } from "./states/prepareInvoiceData";

export interface RootModel extends Models<RootModel> {
  menuDrawer: typeof menuDrawer;
  linkDocument: typeof linkDocument;
  linkSupports: typeof linkSupports;
  electronicDocuments: typeof electronicDocuments;
  validateDocuments: typeof validateDocuments;
  reports: typeof reports;
  reportSingle: typeof reportSingle;
  searcher: typeof searcher;
  invoiceStates: typeof invoiceStates;
  optionsDropdowns: typeof optionsDropdowns;
  notifications: typeof notifications;
  consolidateGlosas: typeof consolidateGlosas;
  auditDetails: typeof auditDetails;
  invoiceData: typeof invoiceData;
  comments: typeof comments;
  messageModal: typeof messageModal;
  iframes: typeof iframes;
  prepareInvoiceData: typeof prepareInvoiceData;
  collapseState: typeof collapseState;
}
export const models: RootModel = { 
  menuDrawer, linkDocument, linkSupports, electronicDocuments, validateDocuments, 
  reports, reportSingle, searcher, invoiceStates, optionsDropdowns, notifications,
  consolidateGlosas, auditDetails, invoiceData, comments, messageModal, iframes, prepareInvoiceData, collapseState
};
