import { typeContentTableRowDetailed } from "./contentTableRowDetailed";

export const LABEL_ACTIVIDAD = 'Actividad';

export const contentDataDocument: typeContentDataDocument[] = [
    { label: 'Nro. de factura', idLabel: 'nroFactura' },
    { label: 'Fecha de expedición', idLabel: 'fechaExpedicion' },
    { label: 'Nro. de Radicado', idLabel: 'numeroRadicado' },
    { label: 'Fecha Radicado', idLabel: 'fechaRadicado' },
    { label: 'Estado', idLabel: 'estado' },
    { label: LABEL_ACTIVIDAD, idLabel: 'actividad' },
    { label: 'Contrato', idLabel: 'contrato' },
    { label: 'Nro. Contrato', idLabel: 'nroContrato' },
    { label: 'Línea de pago', idLabel: 'lineaDePago' },
    { label: 'Fecha inicial de prestación de servicios', idLabel:'fechaInicioServicios'},
    { label: 'Fecha final de prestación de servicios', idLabel:'fechaFinalServicios'},
    { label: 'Asignada A', idLabel: 'assignedTo'},
    { label: 'Total Factura', idLabel: 'totalFactura'},
    { label: 'Total Autorizaciones de Pago', idLabel: 'authorizedPaymentValue'},
    { label: 'Saldo de la Factura', idLabel: 'saldoFactura'},
    { label: 'Valor Cuota de Recuperación', idLabel:'valorCuotaRecuperacion'},
    { label: 'Valor Recuperado', idLabel:'valorRecuperado'},
    { label: 'Código único de factura', idLabel: 'cunFactura' },
]
export const contentDataPS: typeContentDataDocument[] = [
    { label: 'Nit', idLabel: 'nitEmisor' },
    { label: 'Razón Social', idLabel: 'razonSocial' },
    { label: 'Código del Prestador', idLabel: 'codigoPrestador' },
    { label: 'Nombre del IPS o Sede', idLabel: 'nombreComercial' },
    { label: 'Tipo de contribuyente', idLabel: 'tipoContribuyente' },
    { label: 'Régimen Fiscal', idLabel: 'regimenFiscal' },
    { label: 'Responsabilidad Tributaria', idLabel: 'responsabilidadTributaria' },
    { label: 'Actividad Económica', idLabel: 'actividadEconómica' },
    { label: 'Teléfono/ Móvil', idLabel: 'telefonoMovil' },
    { label: 'Dirección', idLabel: 'direccion'},
    { label: 'Municipio - Departamento', idLabel: 'municipio'},
    { label: 'Correo', idLabel: 'email'},
]
export const contentDataAcquirer: typeContentDataDocument[] = [
    { label: 'Tipo de documento', idLabel: 'tipoDocumento' },
    { label: 'Nro. Documento', idLabel: 'nroDocumento' },
    { label: 'Tipo de usuario', idLabel: 'tipoUsuario' },
    { label: 'Primer Apellido del Usuario', idLabel: 'primerApellido'},
    { label: 'Segundo Apellido del Usuario', idLabel: 'segundoApellido'},
    { label: 'Primer Nombre del Usuario', idLabel: 'primerNombre'},
    { label: 'Segundo Nombre del Usuario', idLabel: 'segundoNombre'},
    { label: 'Edad', idLabel: 'edad'},
    { label: 'Tipo de Edad', idLabel: 'tipoEdad'},
    { label: 'Sexo', idLabel: 'sexo'},
    { label: 'Departamento', idLabel: 'departamento'},
    { label: 'Municipio', idLabel: 'municipio'},
    { label: 'Zona', idLabel: 'zona'},
]

export const contentDataTotalRows: typeContentDataDocument[] = [
    {label: 'Valor Factura', idLabel: 'valueInvoice'},
    {label: 'Total Notas Cr\u00e9dito', idLabel: 'valueNoteCredite'},
    {label: 'Total Notas D\u00e9bito', idLabel: 'valueNoteDebite'},
    {label: 'Total Autorizaciones de Pago', idLabel: 'totalAutorizationsPayment'},
    {label: 'Valor Sin Autorización de Pagos', idLabel: 'totalNotAutorizationsPayment'},
]

export const contentDataAuditDetailsPdf: typeContentDataDocument[] = [
    {label: "¿El usuario relacionado a esta factura cumple con toda la documentación?", idLabel: "userInfo"},
    {label: '¿ La Secretaria seccional de salud y protección social de Antioquia debe cubrir los gastos medicos del usuario relacionado? (Validación de derechos )', idLabel: "validateRight"},
    {label: '¿La factura cumple con toda la información técnico administrativa?', idLabel: "adminInfo"},
    {label: '¿La historia clínica del paciente tiene toda la información requerida para realizar la auditoria médica?', idLabel: "clinicHistory"},
    {label: '¿La atención médica del paciente es coherente con la factura, la historia clínica y los RIPS de consultas, urgencias u hospitalización?', idLabel: "medicalAttention"},
    {label: '¿Los procedimientos realizados al paciente son coherentes con la factura, la historia clínica y los RIPS?', idLabel: "process"},
    {label: '¿Los Medicamentos aplicados al paciente son coherentes con la factura, la historia clínica y los RIPS?', idLabel: "medications"},
    {label: '¿Los otros servicios realizados al paciente son coherentes con la factura, la historia clínica y los RIPS?', idLabel: "otherServices"}
]

export const contentDataGridGlosasPdf: typeContentTableRowDetailed[] = [
    { field: 'nameGlosa', headerName: 'Nombre de la Glosa' , minWidth: 200},
    { field: 'valueGlosa', headerName: 'Valor de la Glosa' , minWidth: 160},
    { field: 'statusGlosa', headerName: 'Estado' , minWidth: 160},
    { field: 'valueAccepted', headerName: 'Valor Aceptado' , minWidth: 160},
    { field: 'valueSupported', headerName: 'Valor Sustentado' , minWidth: 160},
    { field: 'valueNotAccepted', headerName: 'Valor no Aceptado' , minWidth: 160},
    { field: 'justification', headerName: 'Justificación' , minWidth: 160},
]

export const contentGridPaymentAuthorizationPdf: typeContentTableRowDetailed[] = [
    { headerName: 'Consecutivo', field: 'id', minWidth: 120},
    { headerName: 'Fecha de Autorización', field: 'dateAuthorization' , minWidth: 180},
    { headerName: 'Valor de Autorización', field: 'valueAuthorization' , minWidth: 180},
    { headerName: 'Auditor que Autoriza', field: 'auditor', minWidth: 200},
    { headerName: 'Linea de pago', field: 'line' , minWidth: 160},
    { headerName: 'Estado', field: 'state' , minWidth: 160},
]

export const contentCommentsnPdf: typeContentTableRowDetailed[] = [
    { field: 'comment', headerName: 'Comentario' , minWidth: 200},
    { field: 'createdAt', headerName: 'Fecha' , minWidth: 160},
    { field: 'createdBy', headerName: 'autor' , minWidth: 160},
]

export const contentDataGlossas = [
    { label: 'Fecha', idLabel: 'dateGlossa' },
    { label: 'Total', idLabel: 'totalGlossa' },
    { label: 'Auditor', idLabel: 'auditGlossa' },
    { label: 'Observaciones', idLabel: 'justification' },
];
export const contentDataAudit = [
    { label: 'Valor consolidado aceptado', idLabel: 'valueAccepted' },
    { label: 'Valor consolidado sustentado', idLabel: 'valueSustained' },
    { label: 'Valor consolidado no aceptado', idLabel: 'valueNotAccepted' },
    { label: 'Fecha', idLabel: 'dateAudit' },
    { label: 'Respuesta Glosa', idLabel: 'justification' },
];
export const contentAutorizationAudit = [
    { label: 'Total Neto a Pagar', idLabel: 'totalValue' },
    { label: 'Total Notas débito', idLabel: 'totalCredits' },
    { label: 'Total Notas crédito', idLabel: 'totalDebits' },
    { label: 'Total glosas', idLabel: 'totalGlosses' },
    { label: 'Valor inicial Autorizado para pagos', idLabel: 'paymentInitial' },
    { label: 'Valor Consolidado Aceptado', idLabel: 'valueAccepted' },
    { label: 'Valor Consolidado Sustentado', idLabel: 'valueSustained' },
    { label: 'Valor Consolidado En no Acuerdo', idLabel: 'valueNotAccepted' },
    { label: 'Nuevo Valor Autorizado para pagos', idLabel: 'newValueAutorizated' },
];

export type typeContentDataDocument = {
    label: string,
    idLabel: string,
}
