
import { Checkbox, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from '../../pages/read-documents/styles/tableResults.module.scss'
import { Box } from '@mui/system';
import Images from 'shared/assets/index';
import { ITable } from './interfaces/table.interface';
import { RouterGuard } from './RouteGuard';
import { Spinner } from './Spinner';
import { SearchItems } from './paginator/SearchItems';
import { debounceFunction, saveSearchHistoryAutorizationPayments, saveSearchHistoryElectronicDocument, selectIndex } from 'shared/utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { useTableResults } from 'shared/hooks/useTableResults';
import { IParamsUrl } from 'shared/models';
import { HeaderSearch } from './table/components/HeaderSearch';
import { FooterTable } from './table/components/FooterTable';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export const TableComponent = (props: ITable<any>)=> {

  const dispatch = useDispatch<Dispatch>();
  const { columns, title,  optionsTrazact,  valuesResultsPages, isDisabledButtonTraza,  downloadExcel,  
    rowSelected, selectAllRows,  selectRow, isStyleActivity = false, idTable, isSearch = true, showBtnCollapse = true } = props
  const { electronicDocuments, isLoading, sizeRows, searcher, checked, handleChangePage, handleChangeSizePage, handleSortByColumn, activityIsDefeated, currentPath } = useTableResults();
  const { content, contentSize, totalElements, size, number, paramsUrl, url } = electronicDocuments;
  const { open, text } = searcher;
  const params: IParamsUrl = {
    ...paramsUrl,
    filter: text
  }
  const [ hasSearch, setHasSearch ] = useState(isSearch)

  const startIconContent = optionsTrazact?.(idTable ?? '');
  const buttonClassName = `${isDisabledButtonTraza ? styles.disabledButton : ''} ${styles.buttons}`.trim();

  const shouldDisplayFlex = optionsTrazact === undefined || downloadExcel === undefined;
  const getSectionClassName = () => {
    const baseClass = styles.contentButtonMobil;
    const flexClass = shouldDisplayFlex ? styles['displayFlex'] : '';
    return `${baseClass} ${flexClass}`.trim();
  };

  useEffect(() => {
    if (open && hasSearch) {
      debounceFunction(() => {
        if (params && url) {
          dispatch.electronicDocuments.getElectronicDocuments(params, url)
        }
        if (url && selectIndex(currentPath[0]) === 1) {
          saveSearchHistoryElectronicDocument({params, url})
        }
        if (url && selectIndex(currentPath[0]) === 3) {
          saveSearchHistoryAutorizationPayments({ params, url })
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    (content && !hasSearch) && setHasSearch(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[content])
  
  useEffect(() => {
    return () => {
      dispatch.collapseState.clearCollapse();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <React.Fragment>
      <Box className={styles.headerTable}>
        {
          showBtnCollapse && (
            <IconButton className={styles.iconButtonStyle} onClick={ () => dispatch.collapseState.openCollapse(!checked.open)}>
              {checked.open ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
            </IconButton>
          )
        }
        {
          title && <p className={'titleMain'}>{title}</p>
        }
        <Collapse in={checked.open} sx={{ width: '100%'}}>
          <HeaderSearch 
            buttonClassName={buttonClassName}
            content={content}
            contentSize={contentSize}
            downloadExcel={downloadExcel}
            getSectionClassName={getSectionClassName}
            idTable={idTable}
            isDisabledButtonTraza={isDisabledButtonTraza}
            optionsTrazact={optionsTrazact}
            props={props}
            startIconContent={startIconContent}
            totalElements={totalElements}
          />
        </Collapse>
      </Box>

      {
        totalElements > 0 ? (
          <Paper className={styles.containerTableResults} style={{display: 'flex', flexDirection:'column'}}>
            <TableContainer className={styles.tableResults}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                {
                  columns.length > 0 && (
                    <>
                      <TableHead>
                        <TableRow className={styles.tablaHeader}>
                          {
                            (rowSelected && selectAllRows) &&
                            <TableCell padding="checkbox" className={styles.tableCell}>
                              <Checkbox
                                color="primary"
                                indeterminate={rowSelected.length > 0 && rowSelected.length < contentSize}
                                checked={contentSize > 0 && rowSelected.length === contentSize}
                                onChange={selectAllRows}
                                inputProps={{
                                  'aria-label': 'select all docs',
                                }}
                                data-testid='checkbox-select-all'
                                id={'selectAll'}
                              />
                            </TableCell>
                          }
                          {
                            columns.map((column, index) => (
                              <React.Fragment key={column.field + '_' + index}>
                              {
                                column?.rowGuard  
                                ? 
                                <RouterGuard roles={column.rowGuard.roles} type="component">
                                  <TableCell
                                    align="left"
                                    className={styles.tableCell}
                                    style={{ minWidth: column.minWidth }}
                                    data-testid={`table-header-${column.field}`}
                                  >
                                    {column.headerName}
                                    {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                  </TableCell>
                                </RouterGuard>
                                :
                                <TableCell
                                  align="left"
                                  className={styles.tableCell}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.headerName}
                                  {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                </TableCell>
                              }
                              </React.Fragment>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody data-testid='table-results-body'>
                        {content!.map((row:any, index) => (
                          <TableRow
                            key={index}
                          >
                            {
                              selectRow && rowSelected &&
                              <TableCell padding="checkbox" style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : '', padding: '0'}}>
                                <Checkbox
                                  color="primary"
                                  checked={rowSelected.indexOf(row.id) !== -1}
                                  inputProps={{
                                    'aria-labelledby': `check${index}`,
                                  }}
                                  onChange={(e) => selectRow(e, row.id)}
                                  className={styles.checkboxFiles}
                                  id={`check${index}`}
                                />
                              </TableCell>
                            }
                            {columns.map((column, index2) => (
                              <React.Fragment key={column.field + '_' + index2}>
                                {
                                  column.rowGuard  
                                  ? 
                                  <RouterGuard roles={column.rowGuard.roles} type="component">
                                    <TableCell
                                      align="left"
                                      className={`${styles.tableCell} ${column.isReduceCellPadding && styles.padding1}`}
                                      style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : ''}}
                                      data-testid={`table-cell-${column.field}`}
                                    >
                                      { column.renderCell ? column.renderCell(row) : row }
                                    </TableCell>
                                  </RouterGuard>
                                  :
                                  <TableCell
                                    align="left"
                                    className={`${styles.tableCell} ${column.isReduceCellPadding && styles.padding1}`}
                                    key={column.field + index2}
                                    style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : ''}}
                                    data-testid={`table-cell2-${column.field}`}
                                  >
                                    { column.renderCell ? column.renderCell(row) : row }
                                  </TableCell>
                                }
                              </React.Fragment>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>  
                    </>
                  )
                }
              </Table>
            </TableContainer>
            <Collapse in={checked.open} sx={{width: '100%'}}>
              <FooterTable
                handleChangePage={handleChangePage}
                handleChangeSizePage={handleChangeSizePage}
                numberPage={number}
                size={size}
                sizeRows={sizeRows}
                totalElements={totalElements}
                valuesResultsPages={valuesResultsPages}
              />
            </Collapse>
          </Paper>
        ): (
          <Box className={styles.containerResults} data-testid='message_no_search'>No se encontraron resultados relacionados a su búsqueda</Box>
        )

      }
      {(isLoading) && <Spinner />}
    </React.Fragment>
  )
}