
import { memo, useMemo } from 'react'
import { useLocation,  Link as RouterLink, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { IconButton, Link, LinkProps, Typography } from '@mui/material';
import { IBreadcrumbsCustom } from '../interfaces';
import styles from '../styles/_breadcrumbsCustom.module.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

  interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
  }
  
  function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
  }
  
export const BreadcrumbsCustom = memo(( { breadcrumbNameMap } : IBreadcrumbsCustom ) => {

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = useMemo(() => location.pathname.split('/').filter((x) => x), [location.pathname]);
  
  const buildBreadcrumbPath = (pathParts: string[], currentIndex: number): string => {
    return '/' + pathParts
    .slice(0, currentIndex + 1)
    .join('/')
  };
  
  const buildBreadcrumbPathCom = (pathParts: string[], currentIndex: number): string => {
    return '/' + pathParts
    .slice(0, currentIndex + 1)
    .join('/')
    .replace(/\/\d+($|\/)/, '/:value$1')
    .replace(/\/\d+($|\/)/, '/:value2$1')
  };
    
  return (
    <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs} separator='/'>
      {
        currentPath.length > 1 && (
          <IconButton aria-label="back"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )
      }
      <LinkRouter className={styles.homeLink}  color="inherit" to="/">
        Home
      </LinkRouter>
      {
        currentPath.map((value: string, index: number) => {
          const last: boolean = index === currentPath.length - 1;
          const to: string = buildBreadcrumbPath(currentPath, index);
          const toCom: string = buildBreadcrumbPathCom(currentPath, index);

          if (last && Object.keys(breadcrumbNameMap).some(key => key === toCom)) {
            
            return (
              <a key={to} className={`${styles.breadcrumbItemLast} ${styles['active']}`}>
                {
                  Object.keys(breadcrumbNameMap).some(key => key === toCom) && breadcrumbNameMap[toCom] as string
                }
              </a>
            );
          }

          if (Object.keys(breadcrumbNameMap).some(key => key === toCom)) {
            return (
              <LinkRouter 
                to={to} 
                key={to}
                className={styles.breadcrumbItem}
              >
                {
                  Object.keys(breadcrumbNameMap).some(key => key === toCom) && breadcrumbNameMap[toCom] as string
                }
              </LinkRouter>
            );
          }
        })
      }
    </Breadcrumbs>
  ) 
});
