import React, { useState, useEffect, useMemo } from 'react'
import { Button, Grid } from '@mui/material'
import { useFormik } from 'formik'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { es } from 'date-fns/locale/es';
import { IDropdown } from 'shared/models'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'shared/store/store'
import styles from '../styles/formFilter.module.scss'
import 'pages/read-documents/styles/_readDocumentsMUI.scss';
import { ComboBoxCustom } from 'shared/components/ComboBoxCustom';
import { IFieldForm } from 'pages/read-documents/model/IFieldForm';
import { ModalStatesPayments } from './ModalStatesPayments';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'
import { Spinner } from 'shared/components';
import { getSearchHistoryAutorizationPayments, saveSearchHistoryAutorizationPayments } from 'shared/utils';
registerLocale('es', es)

export const FormFilter:React.FC<{dataFields:IFieldForm[]}> = ({dataFields}) => {
    
    const dispatch = useDispatch<Dispatch>();
    const { url: urlElectonic, paramsUrl  } = useSelector((state: RootState) => state.electronicDocuments);
    const { paymentsStates } = useSelector((state: RootState) => state.optionsDropdowns);
    const { loading } = useSelector((rootState: RootState) => rootState.loading.models.electronicDocuments);
    const [authorizedAt, setAuthorizedAt] = useState<any[]>([null,null]);
    const [startDateAuthorizedAt, endDateAuthorizedAt] = authorizedAt;
    const [invoiceAt, setInvoiceAt] = useState<any[]>([null,null]);
    const [startDateInvoiceAt, endDateInvoiceAt] = invoiceAt;
    const [radicadoAt, setRadicadoAt] = useState<any[]>([null,null]);
    const [startDateRadicadoAt, endDateRadicadoAt] = radicadoAt;
    const [userId, setUserId] = useState("");
    const [openModalStates, setOpenModalStates] = useState(false);
    const [statesSelected, setStatesSelected] = useState<any[]>([]);
    const dataLocalStorageAutorizationPayments = useMemo(() => getSearchHistoryAutorizationPayments(), []);

    const validateActivitiesSelected = () =>{ 
        return statesSelected.some((value)=> value.selected)
    }

    const onCloseModal = () => {
        setOpenModalStates(false)
    }

    const selectAllActivies = ()=> {
        const allIsSelected = statesSelected.every((state)=> state.selected);
        const currentState = statesSelected.map(st => ({ ...st, selected: !allIsSelected}));
        setStatesSelected(currentState)
    }

    const onSelected = (id:number) => {
        const currentState = statesSelected.map(st=> ({ ...st, selected: st.id === id ? !st.selected : st.selected}));
        setStatesSelected(currentState);
      }


    const buildUrl = (valuesUrl:any) => {
        const statesFilter = statesSelected.filter((value)=> value.selected).map((state:any)=> state.id );
        const indexPathUrl = urlElectonic?.indexOf('?')
        const queryString =   statesFilter.map(item => `stateId=${item}`).join('&');
        const url = `${urlElectonic?.substring(0, indexPathUrl)}?${queryString}`
        
        const params = {
            ...paramsUrl,
            consecutive: valuesUrl.consecutive,
            createdAtStart: startDateAuthorizedAt ? dayjs.utc(startDateAuthorizedAt).format() : '',
            createdAtend: endDateAuthorizedAt ? dayjs.utc(endDateAuthorizedAt).format() : '',
            userName: userId,
            paymentLineId: valuesUrl.paymentLine,
            paymentReference: valuesUrl.paymentReference,
            issuedAtStart: startDateInvoiceAt ? dayjs.utc(startDateInvoiceAt).format() : '',
            issuedAtEnd: endDateInvoiceAt ? dayjs.utc(endDateInvoiceAt).format() : '',
            documentNumber: valuesUrl.invoiceReference,
            employerIdNumber: valuesUrl.nitEntidad,
            filingNumber: valuesUrl.radicado,
            filingAtStart: startDateRadicadoAt ? dayjs.utc(startDateRadicadoAt).format() : '',
            filingAtEnd: endDateRadicadoAt ? dayjs.utc(endDateRadicadoAt).format() : ''
        }
        return {params, url}
    }

    const { handleChange, handleSubmit, values} = useFormik({
        initialValues: {
            consecutive: dataLocalStorageAutorizationPayments?.params?.consecutive ?? '',
            paymentLine: dataLocalStorageAutorizationPayments?.params?.paymentLine ?? '',
            paymentReference: dataLocalStorageAutorizationPayments?.params?.paymentReference ?? '',
            invoiceReference: dataLocalStorageAutorizationPayments?.params?.documentNumber ?? '',
            nitEntidad: dataLocalStorageAutorizationPayments?.params?.employerIdNumber ?? '',
            radicado: dataLocalStorageAutorizationPayments?.params?.filingNumber ?? '',
            userName: dataLocalStorageAutorizationPayments?.params?.userName ?? '',
        },
        onSubmit: (vls) => {
            const result = buildUrl(vls)
            saveSearchHistoryAutorizationPayments(result)
            dispatch.electronicDocuments.getElectronicDocuments(result.params, result.url);
        },
        validateOnChange: false,
        enableReinitialize: true,
    })

    const getValue = (name:string)=> {
        const valueField: any = Object.entries(values).find(([key, value])=> key === name);
        return valueField[1]
    } 

    const getValueDatePickerStart = (name:string)=> {
        if(name === 'authorizedAt') return startDateAuthorizedAt;
        if(name === 'invoiceAt') return startDateInvoiceAt;
        if(name === 'radicadoAt') return startDateRadicadoAt;
    }

    const getValueDatePickerEnd = (name:string)=> {
        if(name === 'authorizedAt') return endDateAuthorizedAt;
        if(name === 'invoiceAt') return endDateInvoiceAt;
        if(name === 'radicadoAt') return endDateRadicadoAt;
    }

    const handleUserAssignment = (value:any)=>{
        const user = value?.email ?? '';
        setUserId(user);
    }

    useEffect(() => {
        dayjs.extend(utc);
        setAuthorizedAt([`01-01-${new Date().getUTCFullYear()}`, new Date()]);
        setInvoiceAt([`01-01-${new Date().getUTCFullYear()}`, new Date()]);
        setRadicadoAt([`01-01-${new Date().getUTCFullYear()}`, new Date()]);
    }, [])

    useEffect(() => {
        const paymentStates = paymentsStates.map(state=> ({...state, selected: false}));
        setStatesSelected(paymentStates);
    }, [])
    

  return (
    <>
        <form className={`${styles.formReadDocuments} form-documents`} noValidate onSubmit={handleSubmit} data-testid='form-read-documents'>
            <Grid container direction={"row"} className={styles.containerReadDocuments}>
            {
                dataFields.map((field,i)=> (
                    <Grid item xs={12} md={6} lg={3} className={ styles.containerGridItem } key={i}>
                        {
                            field.type === 'text' &&
                                <>
                                    <label htmlFor={field.id}>
                                        {field.label}
                                    </label>
                                    <input 
                                        type='text'
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        id={field.id}
                                        data-testid={field.testId}
                                        value={ getValue(field.name) }
                                        onChange={handleChange}
                                    />
                                </>
                        }
                        {
                            field.type === 'Select' &&
                                <>
                                    <label htmlFor={field.id}>
                                        {field.label}
                                    </label>
                                    <select 
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        id={field.id}
                                        data-testid={field.testId}
                                        value={getValue(field.name)}
                                        onChange={handleChange}
                                        disabled={!field.isEnable}
                                    >
                                        <option value="" disabled hidden> {field.placeholder}</option>
                                        {
                                            field?.options?.map((opcion: IDropdown) => (
                                                <option key={opcion.id + i} value={opcion.id}>
                                                    {opcion.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </>
                        }
                        {
                            field.type === 'selectCheck' &&
                                <>
                                    <label htmlFor='selectStates'>
                                        Estados
                                    </label>
                                    <input
                                        className={styles.selectStates}
                                        onClick={()=>setOpenModalStates(true)}
                                        value={validateActivitiesSelected() ? 'Estados seleccionados' : 'Seleccione estados' }
                                        readOnly={true}
                                        id='selectStates'
                                        data-testid={field.testId}
                                    />
                                    {
                                        validateActivitiesSelected() 
                                            ? <CheckIcon className={styles.checkIcon}/>
                                            : <KeyboardArrowDownIcon className={styles.iconArrow} onClick={()=>setOpenModalStates(true)}/>
                                    }
                                </>
                        }
                        {
                            field.type === 'datePicker' &&
                            <>
                                <label htmlFor={field.id}>
                                    {field.label}
                                </label>
                                <DatePicker
                                    id={field.id}
                                    selectsRange={true}
                                    startDate={getValueDatePickerStart(field.name)}
                                    endDate={getValueDatePickerEnd(field.name)}
                                    onChange={(update) => {
                                        field.name === "authorizedAt" ? setAuthorizedAt(update) :
                                        field.name === "invoiceAt" ? setInvoiceAt(update) :
                                        field.name === "radicadoAt" && setRadicadoAt(update)
                                    }}
                                    isClearable={true}
                                    locale={'es'}
                                    dateFormat="dd/MM/yyyy" 
                                    showMonthDropdown
                                    showYearDropdown
                                    data-testid={field.testId}
                                />
                            </>
                        }
                        {
                            field.type === 'Autocomplete' &&
                            <>
                                <span className={styles.spanForLabel}>
                                    {field.label}
                                </span>
                                <ComboBoxCustom handleChange={handleUserAssignment} data={field.options!} placeholder='Seleccione un usuario' focusSelected={true}/>
                            </>
                        }
                    </Grid>
                    
                ))
            }
                <Grid container direction={"row"} justifyContent={'center'}>
                    <Button type='submit' variant='contained' color='secondary' className={styles.buttonSubmit} data-testid="btn-submit">
                        Consultar
                    </Button>
                </Grid>
            </Grid>

        </form>
        <ModalStatesPayments
            onCloseModal={onCloseModal}
            openModal={openModalStates}
            paymentStates={statesSelected}
            selectAllActivies={selectAllActivies}
            onSelected={onSelected}
        />
        {(loading) && <Spinner/>}
    </>

    )
}
