import { useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import Swal from 'sweetalert2';
import { postInvoiceAssignment } from 'shared/api/services/postInvoiceAssignment';
import { IDataSnackBar, IParamsUrl, IRespDocuments, roles } from 'shared/models';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { ModalResetAudit } from '../../pages/read-documents/components/ModalResetAudit';
import { ModalAbortDocument } from 'pages';
import { useAuth } from 'shared/auth';
import { EResponseActivityStatus } from 'shared/constants/api.enum';
import { useWidth } from './HookDetailedConsultation/useWidth';
import { useModalReset } from 'pages/read-documents/hooks/useModalReset';
import { getSearchHistoryAutorizationPayments, getSearchHistoryElectronicDocument } from 'shared/utils';
import { useLocation } from 'react-router-dom';

export const useTableResults = () => {

  const {authService} = useAuth();
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();

  const electronicDocuments = useSelector((state: RootState) => state.electronicDocuments);
  const linkSupports = useSelector((state: RootState) => state.linkSupports);
  const searcher = useSelector((state: RootState) => state.searcher);
  const auditDetails = useSelector((state: RootState) => state.auditDetails);
  const checked = useSelector((state: RootState) => state.collapseState);
  
  const { paramsUrl, content, url } = electronicDocuments;
  const { openModal } = linkSupports;
  const { callGetDetailedDocument } = useDetailedConsultation();
  
  const [isLoading, setIsLoading] = useState(false)
  const [modalAbortDoc, setModalAbortDoc] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [ dataAlert, setDataAlert ] = useState<IDataSnackBar[]>([
    {
      message: '',
      typeIcon: 'success',
    }
  ]);
  const [ rowSelected, setRowSelected ] = useState<number[]>([]);
  const [ activateDropDown, setActivateDropDown ] = useState<boolean>(true);
  const [ dataMassiveAssigment, setDataMassiveAssigment] = useState<any[]>([]);
  const [ sizeRows, setSizeRows] = useState("50");
  const [ orderSort, setOrderSort] = useState<"desc" | "asc">("asc")
  const [anchorElMoreButton, setAnchorElMoreButton] = useState<null | HTMLElement>(null);
  const [docId, setDocId] = useState<number>(0);
  const {activeCode} = useModalReset( docId, callGetDetailedDocument);
  const { width } = useWidth()
  
  const currentPath = useMemo(() => location.pathname.split('/').filter((x) => x), [location.pathname]);
  const dataLocalStorageAutorizationPayments = useMemo(() => getSearchHistoryAutorizationPayments(), []);
  const dataLocalStorage = useMemo(() => getSearchHistoryElectronicDocument(), []);
  
  const openMoreMenu = Boolean(anchorElMoreButton);

  useEffect(() => {
    dataAlert.length > 1 && setShowAlert(true);
  },[dataAlert])

  const activityIsDefeated = (activity: IRespDocuments) => activity.activityStatus?.name === EResponseActivityStatus.FAIL
  
  const handleOpenningMoreMenu = (target: HTMLButtonElement | null) => {
    setAnchorElMoreButton(target);
  }

  const handleSortByColumn = (column:any)=>{
    const params: IParamsUrl = {
      ...paramsUrl,
      orderBy: `${column}:${orderSort}`,
      page: 1
    }
    
    setOrderSort(orderSort === 'asc' ? 'desc' : 'asc');
    dispatch.electronicDocuments.getElectronicDocuments(params, url);
  }

  const handleChangeSizePage = (event: any) => {
    const params: IParamsUrl = {
      ...paramsUrl,
      size: event.target.value,
    }
    setSizeRows(event.target.value);
    dispatch.electronicDocuments.getElectronicDocuments(params, url);
  };
  
  const handleChangePage = (event: unknown, newPage: number) => {
    const params: IParamsUrl = {
      ...paramsUrl,
      page: newPage,
    }
    dispatch.electronicDocuments.getElectronicDocuments(params, url);
  };
  
  const handleUserAssignment = async(idUser?:any, idsDoc?:number[])=>{
    setIsLoading(true);
    await postInvoiceAssignment(idsDoc!, idUser.id!)?.then(()=>{
      dispatch.electronicDocuments.getElectronicDocuments(paramsUrl, url)
      callGetDetailedDocument(idsDoc![0].toString());
      setRowSelected([])
      setDataMassiveAssigment([])
    }).catch((e)=>{
      setIsLoading(false);
    })
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    
  }

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const selectedIndex = rowSelected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowSelected.slice(1));
    } else if (selectedIndex === rowSelected.length - 1) {
      newSelected = newSelected.concat(rowSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowSelected.slice(0, selectedIndex),
        rowSelected.slice(selectedIndex + 1),
      );
    }
    setRowSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = content?.map((x) => x.id);
      setRowSelected(newSelected || []);
      return;
    }
    setRowSelected([]);
  };

  const filterContent = () => {
    return content?.filter(item => rowSelected.includes(item.id)) || [];
  }

  const validateEqualStates = () => {
    const filteredContent = filterContent();
    const isEqualState = filteredContent?.length! > 0 ? !filteredContent?.every(item => item.state.code === filteredContent[0].state.code) : true;
    filteredContent && filteredContent.length > 0 && setDataMassiveAssigment(filteredContent[0].users || []);
    setActivateDropDown(!!isEqualState);
  }

  const validateUserAuditor = () => {
    const userRoles = authService.getUserRoles();
    const userRolIsAuditor = userRoles.some(role => role === roles.auditor);
    return userRolIsAuditor ? true : false;
  }

  const validateInvoicesRecivedStatus = () => {
    const invoicesSelected = filterContent();
    return invoicesSelected.length > 0 && invoicesSelected.every(({ state, stateName }) => state.code === '3' && stateName === 'Factura Recibida')
  }

  const handleMassiveAssignment = (user?:any, idsDoc?:number[])=> {
    Swal.fire({
      title: `¿Está seguro que desea asignar las facturas a ${user.name}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
    })?.then((result:any) => {
      result.isConfirmed && handleUserAssignment(user, idsDoc)
    })
  }

  const onCloseModals = () => {
    dispatch.linkDocument.cleanState();
    dispatch.linkSupports.toogleModal(false);
    dispatch.messageModal.cleanStateMessage() 
    setModalAbortDoc(false);
  }

  const actionModalAbortDE = (id:number) =>{
    dispatch.messageModal.setMessageModal({
      show: true, 
      title: "ANULAR FACTURA ELECTRÓNICA",
      onClose() { onCloseModals() },
      size: 'big',
      description: <ModalAbortDocument onClose={onCloseModals} id={id} callGetDetailedDocument={callGetDetailedDocument}/>
    })
  } 

  const actionModalResetAudit = (doc: IRespDocuments) => {
    setDocId(doc.id);
    doc.currentUser?.id == authService.getUser()?.oid ? (
      dispatch.messageModal.setMessageModal({
        show: true, 
        title: "Reiniciar Auditoria",
        onClose() { onCloseModals() },
        size: activeCode ? 'medium': 'biggest',
        description: <ModalResetAudit onClose={onCloseModals} id={doc.id} bill={auditDetails.bill} callGetDetailedDocument={callGetDetailedDocument}/>
      })
    ) : (
      Swal.fire({
        icon: "warning",
        title: "¡Ups!",
        text: "Debe asegurar que el auditor esté asignado a la factura",
        confirmButtonText: "Aceptar",
      })
    );
    
  } 

  const linkToDian = (cufe:any) => {
    const link = document.createElement('a');
    link.href = `https://catalogo-vpfe.dian.gov.co/document/ShowDocumentToPublic/${cufe || ''}`;
    link.target= "_blank"
    link.click();
  }

  return {
    electronicDocuments,
    searcher,
    modalAbortDoc,
    openModal,
    isLoading,
    sizeRows,
    rowSelected,
    activateDropDown,
    dataMassiveAssigment,
    paramsUrl,
    content,
    anchorElMoreButton,
    openMoreMenu, 
    width,
    checked,
    dataLocalStorageAutorizationPayments,
    dataLocalStorage,
    currentPath,
    handleUserAssignment,
    linkToDian,
    handleChangePage,
    actionModalAbortDE,
    onCloseModals,
    handleChangeSizePage,
    handleSortByColumn,
    handleChangeCheck,
    handleSelectAllClick,
    validateEqualStates,
    handleMassiveAssignment,
    handleOpenningMoreMenu,
    validateInvoicesRecivedStatus,
    filterContent,
    setDataAlert,
    actionModalResetAudit,
    validateUserAuditor,
    showAlert, 
    dataAlert,
    setShowAlert,
    activityIsDefeated
  }
}
