
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from 'pages';
import React, { memo, useEffect, useState } from 'react'
import { IPropCustomTabs } from './model/IPropCustomTabs';
import '../../components/tabs/styles/_customTabs.scss'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Dispatch, RootState } from 'shared';
import { useDispatch, useSelector } from 'react-redux';

export const CustomTabs = memo(( { tabProps, hasStyleCustomTabs, customClass = '', valueTab = 0 }: IPropCustomTabs) => {

  const isDisabled = tabProps.find((tab, index) => index === valueTab)?.disabledTab
  const dispatch = useDispatch<Dispatch>();
  const checked = useSelector((state: RootState) => state.collapseState);

  const [value, setValue] = useState(valueTab);
  const [isDisableState, setIsDisableState] = useState(isDisabled);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(!tabProps[newValue].disabledTab) {
      setIsDisableState(false);
    }
    tabProps[newValue].callService?.( newValue );
    setValue(newValue);
  };

  const handleChangeCollapse = () => {
    dispatch.collapseState.openCollapse(!checked.open);
  };

  useEffect(() => {
    return () => {
      dispatch.collapseState.clearCollapse();
    }
  },[])
  
  return (
    <Box className='content'>
      <section className='headerTabs' data-testid='header_tabs'>
        {
          tabProps[value]?.title && <Typography className='titleMain' data-testid='title_main'>{ tabProps[value].title }</Typography>
        }

        <IconButton className='iconButtonStyle' onClick={handleChangeCollapse}>
          {checked.open ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
        </IconButton>

        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="simple tabs example"
          className={hasStyleCustomTabs ? 'contentTabs' : customClass}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          data-testid='tabs'
        >
          {
            tabProps?.map((tab, index) => (
              <Tab label={tab.label} key={index} disabled={tab.disabledTab} data-testid={`tab_${index}`}/>
            ))
          }
        </Tabs>
      </section>
      {
        !isDisableState && tabProps.map((tab, index) => (
          value === index && (
            <TabPanel value={value} index={index} key={tab.label} className='tabPanelContentCustom'>
              {tab.component}
            </TabPanel>
          )
        ))
      }
      {
        isDisableState && (
          <section className='contentDisabledTab'>
            <Typography data-testid='content_disabled_tab'>Esta pestaña se encuentra deshabilitada</Typography>
          </section>
        )
      }
    </Box>
  )
})

