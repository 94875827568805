
import { useDispatch, useSelector } from "react-redux";
import { IDataProvider } from "../model/IPropsFormBills";
import { useEffect, useState } from "react";
import { Dispatch, GET_ELECTRONIC_DOCS, IFormValues, IParamsUrl, RootState, accesRoles } from "shared";
import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es'
import { useAuth } from "shared/auth";
import { useFormik, FormikErrors } from 'formik'
import Swal from "sweetalert2";
import { saveSearchHistoryElectronicDocument, validateDateRangeByMonth } from "shared/utils";
import { EResponseActivityStatus } from "shared/constants/api.enum";
import { IFieldForm } from "../model/IFieldForm";

export const useFormReadDocument = (dataProvider: IDataProvider | null, dataFields: IFieldForm[]) => {

    const dispatch = useDispatch<Dispatch>();
    const { invoiceStates, optionsDropdowns } = useSelector((state: RootState) => state);
    const [valueDateFrom, setValueDateFrom] = useState<Dayjs | null>(null);
    const [valueDateTo, setValueDateTo] = useState<Dayjs | null>(null);
    const [userId, setUserId] = useState("");
    const [openModalStates, setOpenModalStates] = useState(false);
    const {authService} = useAuth();
    const userRoles = authService.getUserRoles();
    const userRolIsHealthProvider = userRoles.some(role => accesRoles.healthProvider.includes(role));
    const [groupCheck, setGroupCheck] = useState({
        onTime: false,
        nextToExpire: false,
        expired: false,
    });

    useEffect(() => {
        dayjs.extend(utc)
        //los últimos 4 meses  en este rango, según la fecha actual
        const currentDate = dayjs();
        const fromDate = currentDate.subtract(4, 'month');

        setValueDateFrom(fromDate);
        setValueDateTo(currentDate);
    }, [])

    useEffect(() => {
		dispatch.menuDrawer.changeSelectedIndex(1);
	}, [])

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupCheck({
            ...groupCheck,
            [event.target.name]: event.target.checked,
        });
    };

    const onCloseModal = () => setOpenModalStates(false)

    const validate = () => {
        const errorsFormik: FormikErrors<IFormValues> = {};
        const showError = (error: string) =>{
            errorsFormik.idEntidad = "Requerido"
            Swal.fire({
                title: "Datos incompletos",
                text: error,
                icon: "error",
                timer: 8500,
            });
        }

        if((valueDateFrom === null || valueDateTo === null) && (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)))){
            showError("Debe especificar las fechas de la consulta")
        }else if ((valueDateFrom?.isAfter(valueDateTo) || !valueDateFrom?.isValid() || !valueDateTo?.isValid()) && (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)))) {
            showError("Las fechas tienen un formato incorrecto")
        }
        
        //Si el rango de fecha es mayor a 4 meses, no se ha aplicado ningun otro filtro, el usedId es igual a vacio y el invoiceStates todos sus selected son falsos, muestre el error.
        if (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)) && validateDateRangeByMonth(valueDateFrom, valueDateTo, 4) ) {
            errorsFormik.range = 'Por favor introduzca un rango de  fechas que no supere  4 meses'
        }
        return errorsFormik;
    }

    const formatString = (groupCheckProp: any) => {
        const conditions = [
          { key: 'onTime', value: String(optionsDropdowns.activityStatus.find(x=> x.name === EResponseActivityStatus.OK)?.id) },
          { key: 'nextToExpire', value: String(optionsDropdowns.activityStatus.find(x=> x.name === EResponseActivityStatus.WARN)?.id) },
          { key: 'expired', value: String(optionsDropdowns.activityStatus.find(x=> x.name === EResponseActivityStatus.FAIL)?.id) }
        ];
    
        return conditions
          .filter(condition => groupCheckProp[condition.key])
          .map(condition => condition.value)
          .join(',');
      };

    const buildUrl = (valuesUrl:IFormValues) => {
        const activitiesFilter = invoiceStates.flatMap((value)=> value.activities.filter(x=> x.selected)).map((activity)=> ({
          states: activity.titulo
        }))
        const queryString = activitiesFilter.map(item => `states=${item.states}`).join('&');
        const issuedAtStart = valueDateFrom ? dayjs.utc(valueDateFrom).format() : '';
        const issuedAtEnd = valueDateTo ? dayjs.utc(valueDateTo).format() : '';
  
        const params: IParamsUrl = {
          documentNumber: valuesUrl.documentNumber,
          typeDocument: valuesUrl.typeDocument,
          issuedAtStart,
          issuedAtEnd,
          userId,
          stateId: valuesUrl.state,
          providerCode: valuesUrl.idEntidad,
          employerIdNumber: valuesUrl.nitEntidad,
          contractNumber: valuesUrl.contractNumber,        
          states: "",
          activityStatus: formatString(groupCheck)
        }
        return {params,url:`${GET_ELECTRONIC_DOCS}/page?${queryString}`}
      }

    const { handleChange, handleSubmit, values, errors } = useFormik({
        initialValues: {
            settled: '',
            documentNumber: '' ,
            typeDocument: dataFields.find(x => x.id === 'itemformTypeDocument')?.defaulValue ?? '',
            idEntidad: dataProvider?.code ? dataProvider?.code : '',
            nitEntidad: dataProvider?.employerIdNumber ? dataProvider?.employerIdNumber :  '',
            state: '',
            assigned: '',
            tasks: '',
            contractNumber:'',
            range:'',
            activityStatus: '',
        }as IFormValues,
        onSubmit: (vls) => {
            const result = buildUrl(vls)
            saveSearchHistoryElectronicDocument(result)
            dispatch.electronicDocuments.getElectronicDocuments(result.params, result.url);
        },
        validate,
        validateOnChange: false,
        enableReinitialize: true,
    })

    const getValue = (name:string)=> {
        const valueField: any = Object.entries(values).find(([key, value])=> key === name);
        return valueField[1]
    } 

    const validateActivitiesSelected = () => invoiceStates.some((value)=> value.activities.some(x=> x.selected))

    const validateFieldDisabled = (name: string, isEnabled: boolean) => {
        if(name === 'idEntidad') return !!dataProvider?.code
        if(name === 'nitEntidad') return !!dataProvider?.employerIdNumber

        return !isEnabled
    }

    const handleUserAssignment = (value:any) => setUserId(value.id);

    return{
        openModalStates,
        userRolIsHealthProvider,
        errors,
        valueDateTo,
        valueDateFrom,
        groupCheck,
        handleChangeCheck,
        setValueDateTo,
        setValueDateFrom,
        setOpenModalStates,
        onCloseModal,
        handleChange, 
        handleSubmit, 
        getValue,
        validateActivitiesSelected,
        validateFieldDisabled,
        handleUserAssignment
    }
}
