
export const getSearchHistoryElectronicDocument =() => {
    const searchHistory = localStorage.getItem('searchHistoryElectronicDocument');
    if (searchHistory) {
        return JSON.parse(searchHistory);
    }
    return null;
}

export const getSearchHistoryAutorizationPayments =() => {
    const searchHistory = localStorage.getItem('searchHistoryAutorizationPayments');
    if (searchHistory) {
        return JSON.parse(searchHistory);
    }
    return null;
}