import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";

export const collapseState = createModel<RootModel>()({
    state: {
		open: false,
	} as ICollapse,
	reducers: {
		openCollapse(state, payload) {
			state.open = payload;
		},
		clearCollapse(state) {
			state.open = false;
		}
	},
})

interface ICollapse {
	open: boolean;
}
