import { Document, Text, Page, View, StyleSheet, Image, Link } from '@react-pdf/renderer'
import React from 'react';
import { useDocumentPdf } from './useDocumentPdf';
import { IInformationInvoice } from './IdocumentPdf';
import { typeContentTableRowDetailed } from 'pages/detailed-consultation/helpers';
import { removeHTMLTagsFromText } from 'shared/utils';
import dayjs from 'dayjs';
import iconos from 'shared/assets';

const styles = StyleSheet?.create({
    page:{
        padding: '24px 20px 34px 20px',
    },
    container: {
        borderColor: "#1f1f1f",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "2px",
        marginTop: "24px",
        padding: "16px 10px",
        width: "100%",
    },
    containerTitleCardTabs: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "0 16px",
    },
    title: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Times-Bold",
        color: "#1f1f1f"
    },
    logo: {
        width: '300px',
        height: '200px',
        objectFit: 'cover',
        borderRadius: '3px',
        margin: 'auto'
    },
    containerField: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "8px",
        width: "100%"
    },
    field: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0px",
        flex: "1 1 calc(33.333% - 20px)"
    },
    fieldLarge: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0px",
        width: "100%",
        minHeight: "60px",
    },
    label: {
        fontSize: "12px",
        color: "#1f1f1f",
        padding: "4px 0",
        fontWeight: "bold",
        fontFamily: "Times-Bold",
    },
    text: {
        fontSize: '11px',
        color: "#2b2b2b",
        fontWeight: "normal",
        borderBottom: "1px solid #9d9d9d",
        textOverflow: "ellipsis",
        overflow: 'hidden',
        fontFamily: "Times-Roman",
    },
    textTable: {
        fontSize: '9px',
        fontWeight: "normal",
        color: "#2b2b2b",
        textOverflow: "ellipsis",
        textTransform: 'lowercase',
        overflow: 'hidden',
        fontFamily: "Times-Roman"
    },
    table: {
        padding: '5px 0',
        fontSize: '8px',
        width: '100%',
        fontWeight: "bold",
        fontFamily: "Times-Bold",
    },
    row:{
        flexDirection: 'row',
    },
    header:{
        border: '1px solid #ccc',
        color: '#1f1f1f',
        fontWeight: 'bold',
        padding: '10px 2px',
        textAlign: 'center',
        textTransform: 'uppercase',
        flex: 1,
        fontFamily: "Times-Bold",
    },
    cell: {
        color: '#2b2b2b',
        border: '1px solid #ccc',
        fontWeight: 'light',
        padding: '6px 2px',
        textAlign: 'center',
        flex: 1
    },
    containerText: {
        width: '400px'
    },
    pages: {
        position: "absolute",
        bottom: "2",
        right: "2",
        fontSize: "8px"
    },
    fieldDetails:{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "12px 0px",
        width: "100%",
    },
    containerTitleDetails:{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between"
    },
    radioGroup: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    radio: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        borderWidth: 1,
        borderColor: '#000',
        marginRight: 10,
    },
    radioSelected: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#000',
        marginRight: 10,
    },
    bookmark:{
        color: "#7CB9E8",
        fontFamily: "Times-Bold",
        fontSize: "60px",
        fontWeight: "bold",
        position: 'absolute',
        top: '50%',
        left: '50%',
        opacity: 0.3,
        zIndex: "999",
        transform: "rotate(-45deg) scale(1.7) translate(-50%, -50%)"
    },
    imgHeader:{
        height: "50px",
        width: "105px"
    },
    footer:{
        fontSize: "7px",
        padding: "4px 2px",
        position: "absolute",
        bottom: "0px",
        width: "90%"
    }
});

export const DocumentPdf:React.FC<{doc:any}> = ({doc}) => {
    const {contentDocument, bill} = useDocumentPdf(doc);

    const {informationInvoice} = contentDocument!
    const docUrl = `https://trazabilidadfactura.dssa.gov.co/consulta_documentos_electronicos/detalle_documento/${doc.id}`;
    const docNumber = doc.documentNumber;
    const docDate = dayjs(new Date()).format('DD MMMM YYYY HH:mm');

  return (
    <>
    
    <Document data-testid="documentPdf">
        <Page size={"LETTER"} style={styles?.page} bookmark="COPIA">
            <Image style={styles?.imgHeader} src={iconos.logoGobernacion2024} fixed/>
            <Text style={styles?.bookmark} fixed>COPIA</Text>
            <Text render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed style={styles?.pages}/>

            <Text style={styles?.title}>Detalles Factura N° {bill}</Text>
            {
                informationInvoice.length > 0 && informationInvoice.map((invoice: IInformationInvoice, index:number)=> (
                    invoice.type?.includes("object") ?(
                        <View style={styles.container} key={index} break={[0,20,21,22].includes(index) ? false : true}>
                            <View style={styles.containerTitleCardTabs}>
                                <Text style={styles.title}>{invoice.title}</Text>
                            </View>

                            {
                                invoice.hasContent ?
                                    <View style={styles.containerField}>
                                        {
                                            invoice.columns?.map((column: any, index: number) => {
                                                return (
                                                    <View key={index} style={["justification","cunFactura"].some(x => [column.idLabel].includes(x) )  ? styles.fieldLarge : styles.field}>
                                                        <Text style={styles.label}>{column.label || column.headerName} </Text>
                                                        <Text style={styles.text}>{invoice.content[column.idLabel || column.field]} </Text>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View> :
                                    <Text style={styles.label}>Aún no se ha cargado la información correspondiente de esta sección</Text>
                            }
                        </View>
                    ):
                    invoice.type?.includes("array") ? (
                        <View style={styles.container} key={index} break>
                            <View style={styles.containerTitleCardTabs}>
                                <Text style={styles.title}>{invoice.title}</Text>
                            </View>
                            {
                                invoice.hasContent ? (
                                    <View style={styles.table}>
                                        <View style={styles.row} >
                                        {
                                            invoice.columns?.map((col:typeContentTableRowDetailed, i: number)=>(
                                                    <View style={styles.header} key={i}>
                                                        <Text>{col.headerName}</Text>
                                                    </View>
                                            ))
                                        }
                                        </View>

                                        { invoice.content.map((row:any, index:number)=> 
                                                <View key={index} style={styles.row}>
                                                    {
                                                        invoice.columns.map((col:typeContentTableRowDetailed, colIndex:number)=>(
                                                            <View style={styles.cell} key={colIndex}>
                                                                <Text style={styles.textTable}>{row[col.field]} </Text>
                                                            </View>
                                                        ))
                                                    }
                                                </View>
                                            ) 
                                        }
                                    </View>
                                )
                                :
                                    <View>
                                        <Text style={styles.label}>Aún no se ha cargado la información correspondiente de esta sección</Text>
                                    </View>
                            }
                            
                        </View>
                    ):
                    <View style={styles.container} key={index} break={index == 0 ? false : true}>
                        <View style={styles.containerTitleCardTabs}>
                            <Text style={styles.title}>{invoice.title}</Text>
                        </View>

                        {
                            invoice.hasContent ?
                                <View style={styles.containerField}>
                                    {
                                        invoice.columns?.map((column: any, index: number) => {
                                            return (
                                                <View key={index} style={styles.fieldDetails}>
                                                    <View style={styles.containerTitleDetails}>
                                                        <Text style={[styles.label, {width: '80%'}]}>{column.label || column.headerName} </Text>
                                                        <View>
                                                            <View style={styles.radioGroup}>
                                                                <View style={invoice.content[column.idLabel + "Question"]=== true ? styles.radioSelected : styles.radio} />
                                                                <Text style={styles.label}>Si</Text>
                                                            </View>

                                                            <View style={styles.radioGroup}>
                                                                <View style={invoice.content[column.idLabel + "Question"]=== false ? styles.radioSelected : styles.radio} /> 
                                                                <Text style={styles.label}>No</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <Text style={styles.text}>{invoice.content[column.idLabel + "Text"] && removeHTMLTagsFromText(invoice.content[column.idLabel + "Text"])} </Text>
                                                </View>
                                            )
                                        })
                                    }
                                </View> :
                                <Text style={styles.label}>Aún no se ha cargado la información correspondiente de esta sección</Text>
                        }
                    </View>
                ))
            }
            <Text style={styles?.footer} fixed>
                Este documento es una copia digital de la factura {docNumber}, generada el {docDate}.
                Para consultar la información original, por favor acceda a la plataforma de
                Trazabilidad de Facturación Electrónica de la Secretaría Seccional de Salud y Protección Social de Antioquia. a través del siguiente enlace: 
                <Link src={docUrl} fixed >{docUrl}</Link>
            </Text>
        </Page>
     </Document>
    </>
  )
}
