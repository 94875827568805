interface ElectronicDocuments {
    paramsUrl?: {
        typeDocument?: string;
    };
}

interface DataLocalStorage {
    params?: {
        typeDocument?: string;
    };
}

export const getTabValue = (electronicDocuments?: ElectronicDocuments, dataLocalStorage?: DataLocalStorage): number => {
    if (dataLocalStorage?.params?.typeDocument) {
        return parseInt(dataLocalStorage.params.typeDocument);
    }
    if (electronicDocuments?.paramsUrl?.typeDocument && !isNaN(parseInt(electronicDocuments.paramsUrl.typeDocument))) {
        return parseInt(electronicDocuments.paramsUrl.typeDocument);
    }
    return 1;
};