import { IParamsUrl } from "shared/models";

export interface ISaveSearchHistoryElectronicDocument {
    params?: IParamsUrl;
    url?: string;
}

export const saveSearchHistoryElectronicDocument = (searchData: ISaveSearchHistoryElectronicDocument) => {

    const { params, url } = searchData;

    const dataToSave = {
        params,
        url,
    };
    
    for (const key in params) {
        if (
            params[key as keyof typeof params] !== undefined &&
            params[key as keyof typeof params] !== null
        ) {
            (dataToSave.params as Record<string, unknown>)[key] = params[key as keyof typeof params];
        }
    }
    
    localStorage.setItem('searchHistoryElectronicDocument', JSON.stringify(dataToSave));
}

export const saveSearchHistoryAutorizationPayments = (searchData: ISaveSearchHistoryElectronicDocument) => {

    const { params, url } = searchData;

    const dataToSave = {
        params,
        url,
    };
    
    for (const key in params) {
        if (
            params[key as keyof typeof params] !== undefined &&
            params[key as keyof typeof params] !== null
        ) {
            (dataToSave.params as Record<string, unknown>)[key] = params[key as keyof typeof params];
        }
    }
    
    localStorage.setItem('searchHistoryAutorizationPayments', JSON.stringify(dataToSave));
}