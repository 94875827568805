import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { TabDetails, TabDocumentsRelated, TabRips, TablaTrazabilidad, TabAuditDetail } from '../components';
import { contentDataAcquirer, contentDataDocument, contentDataPS } from '../helpers/contentDataDocument';
import { ITimeLine } from '../Models/ITimeLine';
import {IDetailedParametersServices, IElectronicDocumentLine, accesRoles, stateTypes} from 'shared/models';
import Image from 'shared/assets/index';
import Swal from 'sweetalert2';
import {TabSupports} from '../components/TabSupports';
import {MultiResponseMap, parallelData, singleData} from 'shared/api/services/getData';
import {GET_ELECTRONIC_DOCS} from 'shared/api';
import { RouterGuard } from 'shared/components';
import { activities, linesStates, statesActivities } from 'shared/models/lineStates';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'shared/auth';
import { TabGlosses } from '../components/TabGlosses';
import { TabAuthorizatePayments } from '../components/TabAuthorizatePayments';

export const useDetailedConsultation = () => {

    const [params, setParams] = useSearchParams();
    const dispatch = useDispatch<Dispatch>();
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [detailedParameters, setDetailedParameters] = useState<any[]>([]);
    const [documentLinesParameters, setDocumentLineParameters] = useState<IElectronicDocumentLine[]>([]);
    const [timeLine, setTimeLine] = useState<ITimeLine>({
        receivedAt: undefined,
        conciliationAt: undefined,
        filedAt: undefined,
        authorizedToPaymentAt: undefined,
        paid_at: undefined,
        cancelledAt: undefined,
        lastTimelineStage: undefined,
    });
    
    const auditDetails = useSelector((state: RootState) => state.auditDetails);
    const { billingState, bill, provider } = auditDetails;
    const {authService} = useAuth();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setParams(`option=${newValue}`);
    };

    const checkToEnableTabAuditDetail = (): boolean => {
        const userRoles = authService.getUserRoles();
        const userRolIsHealthProvider = userRoles.some(role => accesRoles.healthProvider.includes(role));
        const isAuditorRol = userRoles.some(role => accesRoles.auditor.includes(role));

        const billHasPaymentState = [...statesActivities.audit, ...statesActivities.payments].includes(billingState.code);
        const billHasAuditState = [...linesStates.auditTail].includes(billingState.code);

        const shouldEnableForAuditor = isAuditorRol && (billHasPaymentState || billHasAuditState);
        const shouldEnableForHealthProvider = !isAuditorRol && userRolIsHealthProvider && billHasPaymentState;

        return shouldEnableForAuditor || shouldEnableForHealthProvider;
    }

    useEffect(() => {
        const paramTab = Number(params.get("option"));
        if((paramTab >= 6) && ![...statesActivities.audit, ...statesActivities.payments].includes(billingState.code)){
            setValue(0)
        }else if(paramTab === 5 && !checkToEnableTabAuditDetail()){
            setValue(0)
        }else{
            setValue( paramTab || 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ params, billingState.code ])

    const contentTabs = [
        {
            id: 0,
            label: 'Detalles',
            content: 
                <TabDetails 
                    detailedParameters={detailedParameters}
                    documentLines={documentLinesParameters}
                    showDocumentLines
                />,
            disable: false
        },
        {
            id: 1,
            label: 'Soportes',
            content: value === 1 ? <TabSupports/> : <></>,
            disable: false,
            hidden: false
        },
        {
            id: 2,
            label: 'Trazabilidad',
            content: value === 2 ? <TablaTrazabilidad/> : <></>,
            disable: false,
            hidden: false
        },
        {
            id: 3,
            label: 'RIPS',
            content: value === 3 ? <TabRips/> : <></>,
            disable: false,
            hidden: false
        },
        {
            id: 4,
            label: 'Documentos electrónicos',
            content: value === 4 ? <TabDocumentsRelated/> : <></>,
            disable: false,
            hidden: false
            
        },
        {
            id: 5,
            label: 'Detalle de auditoría',
            content: value === 5 ? (
                    <TabAuditDetail NumberInvoice={bill} /> 
                 )
                 : <>Aún no se ha cargado la información correspondiente de esta sección</>,
            disable: !checkToEnableTabAuditDetail(),
            hidden: !checkToEnableTabAuditDetail()
        },
        {
            id: 6,
            label: 'Glosas',
            content: value === 6 ? (
                    <TabGlosses NumberInvoice={bill} /> 
                 )
                 : <>Aún no se ha cargado la información correspondiente de esta sección</>,
            disable: ![...statesActivities.audit, ...statesActivities.payments].includes(billingState.code),
            hidden: ![...statesActivities.audit, ...statesActivities.payments].includes(billingState.code)
        },
        {
            id: 7,
            label: 'Autorización de pagos',
            content: value === 7 ? (
                <RouterGuard roles={accesRoles.reader} type="component">
                    <TabAuthorizatePayments />
                </RouterGuard>
                 )
                 : <>Aún no se ha cargado la información correspondiente de esta sección</>,
            disable: ![...statesActivities.audit, ...statesActivities.payments].includes(billingState.code),
            hidden: ![...statesActivities.audit, ...statesActivities.payments].includes(billingState.code)
        },
    ]

    const convertServiceDocumentDetails = (multiResponseMap: MultiResponseMap) => {
        const detailedParametersServices: IDetailedParametersServices = multiResponseMap.documentInfo.data;
        const documentLines: IElectronicDocumentLine[] = multiResponseMap.documentLines.data;

        dispatch.auditDetails.addDetails({
            billingState:{
                code: detailedParametersServices?.state?.code || '',
                name: detailedParametersServices?.state?.name || ''
            },
            paymentValues: {
                subtotalValue: detailedParametersServices?.subtotalValue || 0,
                creditTotalValue: detailedParametersServices?.creditTotalValue || 0,
                debitTotalValue: detailedParametersServices?.debitTotalValue || 0,
                glossesTotalValue: detailedParametersServices?.glossesTotalValue || 0,
                paymentAuthorizationTotalValue: ( detailedParametersServices?.paymentAuthorizationInitialValue) || 0,
                paymentAuthorizationCurrentValue: detailedParametersServices?.paymentAuthorizationCurrentValue || 0,
                acceptedConsolidatedValue: detailedParametersServices?.acceptedConsolidatedValue || '0',
                consolidatedInNoAgreementValue: detailedParametersServices?.consolidatedInNoAgreementValue || '0',
                sustainedConsolidatedValue: detailedParametersServices?.sustainedConsolidatedValue || '0'
            },
            bill: detailedParametersServices?.documentNumber,
            totalValue: detailedParametersServices?.totalValue || 0,
            glossesGeneralDetailStatus: detailedParametersServices?.glossesGeneralDetailStatus!,
            provider:{
                name: detailedParametersServices?.provider?.name,
                code: detailedParametersServices?.provider?.code
            },
            activityStatus: {
                id: String(detailedParametersServices?.activityStatus?.id )?? '',
                code: detailedParametersServices?.activityStatus?.code ?? '',
                name: detailedParametersServices?.activityStatus?.name ?? ''
            },
            resolution: detailedParametersServices.resolution || '',
            documentTotals: {
                totalValue: detailedParametersServices?.totalValue || 0,
                taxesTotalValue: detailedParametersServices?.taxesTotalValue || 0,
                discountsTotalValue: detailedParametersServices?.discountsTotalValue || 0,
                debitTotalValue: detailedParametersServices?.debitTotalValue || 0,
                creditTotalValue: detailedParametersServices?.creditTotalValue || 0,
                authorizations: detailedParametersServices?.paymentAuthorizationTotalValue || 0,
                totalBalance: detailedParametersServices?.totalBalance || 0
            }
        })

        dispatch.invoiceData.addDataGeneralParameters({
            columns: contentDataDocument,
            content: {
                nroFactura: detailedParametersServices?.documentNumber || "Información no disponible",
                fechaExpedicion: detailedParametersServices?.issuedAt ? dayjs(detailedParametersServices?.issuedAt).format('DD MMMM YYYY') : "Información no disponible",
                cunFactura: detailedParametersServices?.uniqueCodeElectronicBilling || "Información no disponible",
                numeroRadicado: detailedParametersServices?.filingNumber || "Información no disponible",
                fechaRadicado: detailedParametersServices?.filingNumberCreatedAt ? dayjs(detailedParametersServices?.filingNumberCreatedAt).format('DD MMMM YYYY') : "Información no disponible",
                estado: detailedParametersServices?.timeline ? detailedParametersServices?.timeline.lastTimelineStage.name : "Información no disponible",
                actividad: detailedParametersServices?.state ? detailedParametersServices.state.name : "Información no disponible",
                currentUser: detailedParametersServices?.currentUser,
                users: detailedParametersServices?.users || [],
                contrato: detailedParametersServices?.contractNumber ? "Si" : "No",
                nroContrato: detailedParametersServices?.contractNumber || "Información no disponible",
                lineaDePago: detailedParametersServices?.paymentLine?.name || "Información no disponible",
                fechaInicioServicios: detailedParametersServices?.ripsReportDates?.attentionPeriodStartedAt ? dayjs(detailedParametersServices?.ripsReportDates.attentionPeriodStartedAt).format('DD MMMM YYYY') : "Información no disponible",
                fechaFinalServicios: detailedParametersServices?.ripsReportDates?.attentionPeriodEndedAt ? dayjs(detailedParametersServices.ripsReportDates.attentionPeriodEndedAt).format('DD MMMM YYYY') : "Información no disponible",
                assignedTo: detailedParametersServices?.currentUser ? detailedParametersServices?.currentUser.name : "Información no disponible",
                totalFactura: detailedParametersServices?.totalValue,
                saldoFactura: detailedParametersServices?.totalBalance,
                valorCuotaRecuperacion: detailedParametersServices?.discountsTotalValue ? detailedParametersServices?.discountsTotalValue : 0,
                valorRecuperado: detailedParametersServices?.recoveryValue ? detailedParametersServices?.recoveryValue : 0,
                fechaAceptacionResultado: detailedParametersServices?.acceptanceDateConsolidatedValue,
                acceptedValue: detailedParametersServices?.acceptedValue,
                authorizedPaymentValue: detailedParametersServices?.paymentAuthorizationTotalValue || 0
            }
        })
        setDetailedParameters([
            {
                icon: Image.iconoHospital,
                title: 'Información del prestador de salud',
                columns: contentDataPS,
                hasContent: true,
                content: {
                    nitEmisor: detailedParametersServices?.provider ? detailedParametersServices?.provider?.employerIdNumber : "Información no disponible",
                    razonSocial: detailedParametersServices?.provider ? detailedParametersServices?.provider?.name : "Información no disponible",
                    codigoPrestador: detailedParametersServices?.provider ? detailedParametersServices?.provider?.code : "Información no disponible",
                    nombreComercial: detailedParametersServices?.provider ? detailedParametersServices?.provider?.name : "Información no disponible",                    
                    tipoContribuyente: detailedParametersServices?.taxpayerType?.name ? detailedParametersServices?.taxpayerType?.name : "Información no disponible",
                    regimenFiscal: detailedParametersServices?.taxRegime?.name ? detailedParametersServices?.taxRegime?.name : "Información no disponible",
                    responsabilidadTributaria: detailedParametersServices?.taxLiability?.name ? detailedParametersServices?.taxLiability.name : "Información no disponible",
                    actividadEconómica: detailedParametersServices?.economicActivityDescription ? detailedParametersServices?.economicActivityDescription : "Información no disponible",
                    telefonoMovil: detailedParametersServices?.provider ? detailedParametersServices?.provider?.phoneNumberOne : "Información no disponible",
                    direccion: detailedParametersServices?.provider ? detailedParametersServices?.provider?.address : "Información no disponible",
                    municipio: detailedParametersServices?.province ? detailedParametersServices?.province?.name : "Información no disponible",
                    email: detailedParametersServices?.provider ? detailedParametersServices?.provider?.email : "Información no disponible",
                },
            },
            {
                icon: Image.iconoBanco,
                title: 'Información del Paciente Atendido',
                columns: contentDataAcquirer,
                hasContent: detailedParametersServices.userHealthInformation?.identificationNumber !== undefined,
                content: {
                    tipoDocumento: detailedParametersServices?.userHealthInformation?.identificationType || "Información no disponible",
                    nroDocumento: detailedParametersServices?.userHealthInformation?.identificationNumber || "Información no disponible",
                    tipoUsuario: detailedParametersServices?.userHealthInformation?.userType || "Información no disponible",
                    primerApellido: detailedParametersServices?.userHealthInformation?.firstLastName || "Información no disponible",
                    segundoApellido: detailedParametersServices?.userHealthInformation?.secondLastName || "Información no disponible",
                    primerNombre: detailedParametersServices?.userHealthInformation?.firstName || "Información no disponible",
                    segundoNombre: detailedParametersServices?.userHealthInformation?.middleName || "Información no disponible",
                    edad: detailedParametersServices?.userHealthInformation?.age || "Información no disponible",
                    tipoEdad: detailedParametersServices?.userHealthInformation?.ageUnit || "Información no disponible",
                    sexo: detailedParametersServices?.userHealthInformation?.gender || "Información no disponible",
                    departamento: detailedParametersServices?.userHealthInformation?.province || "Información no disponible",
                    municipio: detailedParametersServices?.userHealthInformation?.city || "Información no disponible",
                    zona: detailedParametersServices?.userHealthInformation?.residenceArea || "Información no disponible",
                }
            },
        ])

        setDocumentLineParameters(documentLines);
        setTimeLine({
            receivedAt: detailedParametersServices?.timeline?.receivedAt,
            cancelledAt: detailedParametersServices?.timeline?.cancelledAt,
            filedAt: detailedParametersServices?.timeline?.filedAt,
            conciliationAt: detailedParametersServices?.timeline?.conciliatedAt,
            authorizedToPaymentAt: detailedParametersServices?.timeline?.authorizedToPaymentAt,
            paid_at: detailedParametersServices?.timeline?.paid_at,
            lastTimelineStage: {
                code: detailedParametersServices?.timeline?.lastTimelineStage?.code,
                name: detailedParametersServices?.timeline?.lastTimelineStage?.name
            }
        })
        dispatch.auditDetails.setReadOnly(detailsAreReadOnly(detailedParametersServices?.timeline?.lastTimelineStage?.code || ''));
    }

    const callGetDetailedDocument = (idDocument: string) => {
        const requests = {
            documentInfo: singleData(`${GET_ELECTRONIC_DOCS}/${idDocument}`),
            documentLines: singleData(`${GET_ELECTRONIC_DOCS}/${idDocument}/lines`)
        };
        parallelData(requests)
            .then(responseMap => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
                convertServiceDocumentDetails(responseMap);
            }).catch((e) => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

                Swal.fire({
                    title: "No se encontró información de la factura",
                    icon: "error",
                    timer: 3000,
                });
            })
    }

    const detailsAreReadOnly = (state: string) => {
        const readOnly = stateTypes.includes(state);
        return readOnly;
    }

    return {
        value,
        documentLines: documentLinesParameters,
        contentTabs,
        detailedParameters,
        timeLine,
        isLoading,
        provider,
        handleChange,
        convertServiceDocumentDetails,
        callGetDetailedDocument
    }

}


