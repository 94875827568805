import React, {useEffect, useState} from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ButtonAction } from 'shared/components/ButtonAction'
import { IParamsUrl, IRespDocuments } from '../../../shared/models/store/IElectronicDocuments';
import { Link, useLocation } from 'react-router-dom'
import { accesRoles } from 'shared/models'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'shared/store/store'
import '../styles/_menuFunctions.scss'
import { ModalResultAcepptance } from './ModalResultAcepptance';
import { statesActivities } from 'shared/models/lineStates';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { RESOLUTION_2275_2023 } from '../helper/resolutions';
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { DocumentPdf } from 'pages/detailed-consultation/components/documentToPdf/DocumentPdf';
import { getData } from 'shared/api/services';
import { GET_ELECTRONIC_DOCS, Spinner, SpinnerText } from 'shared';

interface Props {
  paramsUrl?: IParamsUrl,
  doc:IRespDocuments,
  linkToDian: (cufe: any)=>void,
  actionModalAbortDE: (id:number)=>void,
  actionModalResetAudit: (doc:IRespDocuments)=>void,
  detailed?: boolean,
  isBillValueNotAccept: boolean,
}

export const MenuFunctions =({doc, paramsUrl, actionModalAbortDE, actionModalResetAudit, detailed, isBillValueNotAccept }: Props) => {

  const dispatch = useDispatch<Dispatch>();
  const messageModal = useSelector((state: RootState) => state.messageModal);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalResultAcceptance, setOpenModalResultAcceptance] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<string>('');
  const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
  const [ responseGetDocuments, setResponseGetDocuments ] = useState<any>(null);
  const [instance, updateInstance] = usePDF();

  const location = useLocation();
  const isOpenFunctions = Boolean(anchorEl);

  const { callGetDetailedDocument } = useDetailedConsultation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if(location.pathname.includes('/consulta_documentos_electronicos/detalle_documento')){
      setShowSpinner(true);
  
      getData(`${GET_ELECTRONIC_DOCS}/${doc.id}/detail-export`)?.then((res)=>{
        updateInstance(<DocumentPdf doc={res} /> )
        setResponseGetDocuments(res);
      }).finally(() => {
        setShowSpinner(false);
      })
    }
  
  };

  const handleClose = () => {
    setAnchorEl(null);
  };  

  const onOpenModalResultAcceptance = (open: boolean, documentId?: number) => {

    setOpenModalResultAcceptance(open);
    if (documentId) {
      const document = documentId.toString();
      callGetDetailedDocument(document);
      setSelectedDocument(document);
    }
  }

  useEffect(() => {
    statesActivities.acceptanceOfTheAuditResult.includes(doc?.state?.code) && dispatch.consolidateGlosas.getlistGlosasAsync(doc.id)
  }, [doc])

  useEffect(() => {
    messageModal?.show && handleClose();
  },[messageModal?.show])

  useEffect(() => {
    instance?.loading ? setShowSpinner(true) : setShowSpinner(false);
  }, [instance?.loading])
  

  const handleDownload = () => {
    if (instance?.loading) {
      setShowSpinner(true)
    } else if (instance?.error) {
      console.log('Error al generar el PDF.', instance?.error);
    } else {
      setShowSpinner(false)
      const url = instance?.url!;
      const link = document.createElement('a');
      link.href = url;
      link.download = `${doc.documentNumber}.pdf`;
      link.click();
    }
  };

  return (
    <div className={'MenuFunctions'}>
      <IconButton
        data-testid={`buttonFunctions`}
        onClick={handleClick}
      >
        Acciones <ExpandMore/>
      </IconButton>
      <Menu
        id="menu-options"
        data-testid='menuOptions'
        className={'menuOptions'}
        anchorEl={anchorEl}
        open={isOpenFunctions}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem> 
          <ButtonAction
            roles={accesRoles.reader}
            disabled={!!detailed}
            dataTestid='ver_factura'
          >
            <Link to={`/consulta_documentos_electronicos/detalle_documento/${doc.id}`} data-testid='link-detailed'>
              Ver factura
            </Link>
          </ButtonAction>
        </MenuItem>
        <MenuItem>
          <ButtonAction
            roles={accesRoles.healthProvider}
            disabled={!statesActivities.received.includes(doc?.state?.code)}
          >
            <Link to={`/consulta_documentos_electronicos/radicar_facturas/${doc.id}`} data-testid='submit_invoice'>
              Radicar Factura
            </Link>
          </ButtonAction>
        </MenuItem>  
        <MenuItem>
          <ButtonAction
            roles={accesRoles.validator}
            disabled={doc?.type?.code !== "01" || !statesActivities.received.includes(doc?.state?.code)}
            action={()=>actionModalAbortDE(doc.id)}
            dataTestid='anular_factura'
          >
            <span>Anular Factura Electrónica</span>
          </ButtonAction>
        </MenuItem>
        <MenuItem>
          <ButtonAction
            roles={accesRoles.reader}
            disabled={ doc?.resolution === RESOLUTION_2275_2023 || doc?.type?.code !== "01" || doc?.state?.code === "1" || doc?.state?.code === "1.3" || doc?.state?.code === "1.9"}
            dataTestid='downloadsRips'
            action={()=>dispatch.electronicDocuments.getFileExcelToExport({
              url:`${doc?.id}/rips/download`,
              text:"RIPS descargados con Exito"
            })}
          >
            <span>Descargar Rips</span>
          </ButtonAction>
        </MenuItem>
        <MenuItem>
          <ButtonAction
              roles={accesRoles.reader}
              disabled={doc?.state?.code === "1" || doc?.state?.code === "1.3" || doc?.state?.code === "1.9" || doc?.state?.code === "4"}
              action={()=>dispatch.electronicDocuments.getFileExcelToExport({
                url:`${doc.id}/supports/download`,
                text:"SOPORTES descargados con Exito"
              })}
              dataTestid='descargar_soporte'
              >
              <span>Descargar Soportes</span> 
          </ButtonAction>
        </MenuItem>                           
        <MenuItem>
          <ButtonAction
            roles={accesRoles.auditor}
            disabled={doc?.state?.code !== "7"}
          >
            <Link to={`/consulta_documentos_electronicos/detalle_documento/${doc.id}?option=5`} data-testid='link-audit'>
              Auditar Factura
            </Link>
          </ButtonAction>
        </MenuItem> 
        <MenuItem>
          <ButtonAction
            roles={accesRoles.auditor}
            disabled={!statesActivities.acceptanceOfTheAuditResult.includes(doc?.state?.code) || !isBillValueNotAccept}
            dataTestid='manageBalance'
            action={() => { onOpenModalResultAcceptance(true, doc.id); handleClose(); }}
          >
            <span>Gestionar saldo en No Acuerdo</span>
          </ButtonAction>
        </MenuItem> 
          {
            location.pathname.includes('/consulta_documentos_electronicos/detalle_documento') && (
              showSpinner ? 
                <MenuItem disabled={true}>
                    <SpinnerText />
                </MenuItem>
                :
                <MenuItem>
                    <ButtonAction
                      roles={accesRoles.reader}
                      disabled={false}
                      dataTestid='exportToPdf'
                      action={handleDownload}
                    >
                      <span>Exportar a PDF</span>
                    </ButtonAction>
                </MenuItem> 
            )
          }
            {/* <ButtonAction
                roles={accesRoles.auditor}
                disabled={false}
                dataTestid='exportToPdf'
                action={() => { onOpenModalResultAcceptance(true, doc.id); handleClose(); }}
              >
                <span>Exportar a PDF</span>
              </ButtonAction> */}
        {
          doc?.state?.code === '7.2.2' &&
          <MenuItem>
            <ButtonAction
              roles={accesRoles.auditor}
              disabled={doc?.state?.code !== '7.2.2'}
              dataTestid='consolidar_auditoria'
            >
              <Link to={`/consulta_documentos_electronicos/detalle_documento/${doc.id}?option=6`} data-testid='link-audit'>
                Conciliar Auditoría
              </Link>
            </ButtonAction>
          </MenuItem> 
        }
        {
          [...statesActivities.audit, ...statesActivities.payments].includes(doc?.state?.code) &&
          <MenuItem>
            <ButtonAction
              roles={accesRoles.auditor}
              disabled={![...statesActivities.audit, ...statesActivities.payments].includes(doc?.state?.code)}
              action={()=> actionModalResetAudit(doc)}
              dataTestid='reiniciar_factura'
            >
              <span>
                Reiniciar Auditoria
              </span>
            </ButtonAction>
          </MenuItem> 
        }
      </Menu>
        {
          openModalResultAcceptance &&
          <ModalResultAcepptance
            open={openModalResultAcceptance}
            selectedDocument={selectedDocument}
            onOpen={onOpenModalResultAcceptance}
          />
        }
        {location.pathname.includes('/consulta_documentos_electronicos/detalle_documento') && (showSpinner) && <Spinner/>}
    </div>
  )
}
