import React, { useEffect, useState } from 'react'
import { IDocument } from './IdocumentPdf';
import { contentAttachmentPdf, contentAutorizationAudit, contentCommentsnPdf, contentDataAcquirer, contentDataAudit, contentDataAuditDetailsPdf, contentDataDocument, contentDataGlossas, contentDataGridGlosasPdf, contentDataPS, contentDataTotalRows, contentERelatedPdf, contentGridPaymentAuthorizationPdf, contentInvoicedItems, contentTableRowDetailed, contentTableRowRipsACPdf, contentTableRowRipsAF, contentTableRowRipsAH, contentTableRowRipsAMPdf, contentTableRowRipsANPdf, contentTableRowRipsAP, contentTableRowRipsATPdf, contentTableRowRipsAU, contentTableRowRipsCUV, contentTableRowRipsUS } from 'pages/detailed-consultation/helpers';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ripsReportAC, ripsReportAM, ripsReportAN, ripsReportAP, ripsReportAT } from 'pages/detailed-consultation/Models/IResponseTabRips';
import { removeHTMLTagsFromText } from 'shared/utils';

export const useDocumentPdf = (doc:any) => {
    
    const [bill, setBill] = useState('');
    const [contentDocument, setContentDocument] = useState<IDocument>({
        informationInvoice: []
    });

    const transformDataDocumentPdf = (detailedParametersServices:any)=>{
        setBill(detailedParametersServices?.documentNumber)
        detailedParametersServices && (
            setContentDocument(
                {
                    informationInvoice: [
                        {
                            type: "object",
                            title: 'Información General de la Factura',
                            columns: contentDataDocument,
                            hasContent: true,
                            content: {
                                nroFactura: detailedParametersServices?.documentNumber || "Información no disponible",
                                fechaExpedicion: detailedParametersServices?.issuedAt ? dayjs(detailedParametersServices?.issuedAt).format('DD MMMM YYYY') : "Información no disponible",
                                cunFactura: detailedParametersServices?.uniqueCodeElectronicBilling || "Información no disponible",
                                numeroRadicado: detailedParametersServices?.filingNumber || "Información no disponible",
                                fechaRadicado: detailedParametersServices?.filingNumberCreatedAt ? dayjs(detailedParametersServices?.filingNumberCreatedAt).format('DD MMMM YYYY') : "Información no disponible",
                                estado: detailedParametersServices?.timeline ? detailedParametersServices?.timeline.lastTimelineStage.name : "Información no disponible",
                                actividad: detailedParametersServices?.state ? detailedParametersServices.state.name : "Información no disponible",
                                currentUser: detailedParametersServices?.currentUser.name || "Información no disponible",
                                users: detailedParametersServices?.users || [],
                                contrato: detailedParametersServices?.contractNumber ? "Si" : "No",
                                nroContrato: detailedParametersServices?.contractNumber || "Información no disponible",
                                lineaDePago: detailedParametersServices?.paymentLine?.name || "Información no disponible",
                                fechaInicioServicios: detailedParametersServices?.ripsReportDates?.attentionPeriodStartedAt ? dayjs(detailedParametersServices?.ripsReportDates.attentionPeriodStartedAt).format('DD MMMM YYYY') : "Información no disponible",
                                fechaFinalServicios: detailedParametersServices?.ripsReportDates?.attentionPeriodEndedAt ? dayjs(detailedParametersServices.ripsReportDates.attentionPeriodEndedAt).format('DD MMMM YYYY') : "Información no disponible",
                                assignedTo: detailedParametersServices?.currentUser ? detailedParametersServices?.currentUser.name : "Información no disponible",
                                totalFactura: detailedParametersServices?.totalValue,
                                saldoFactura: detailedParametersServices?.totalBalance,
                                valorCuotaRecuperacion: detailedParametersServices?.discountsTotalValue ? detailedParametersServices?.discountsTotalValue : 0,
                                valorRecuperado: detailedParametersServices?.recoveryValue ? detailedParametersServices?.recoveryValue : 0,
                                fechaAceptacionResultado: detailedParametersServices?.acceptanceDateConsolidatedValue,
                                acceptedValue: detailedParametersServices?.acceptedValue,
                                authorizedPaymentValue: detailedParametersServices?.paymentAuthorizationTotalValue || 0
                            }
                        },
                        {
                            type: "object",
                            title: 'Información del prestador de salud',
                            columns: contentDataPS,
                            hasContent: true,
                            content: {
                                nitEmisor: detailedParametersServices?.provider ? detailedParametersServices?.provider?.employerIdNumber : "Información no disponible",
                                razonSocial: detailedParametersServices?.provider ? detailedParametersServices?.provider?.name : "Información no disponible",
                                codigoPrestador: detailedParametersServices?.provider ? detailedParametersServices?.provider?.code : "Información no disponible",
                                nombreComercial: detailedParametersServices?.provider ? detailedParametersServices?.provider?.name : "Información no disponible",                    
                                tipoContribuyente: detailedParametersServices?.taxpayerType?.name ? detailedParametersServices?.taxpayerType?.name : "Información no disponible",
                                regimenFiscal: detailedParametersServices?.taxRegime?.name ? detailedParametersServices?.taxRegime?.name : "Información no disponible",
                                responsabilidadTributaria: detailedParametersServices?.taxLiability?.name ? detailedParametersServices?.taxLiability.name : "Información no disponible",
                                actividadEconómica: detailedParametersServices?.economicActivityDescription ? detailedParametersServices?.economicActivityDescription : "Información no disponible",
                                telefonoMovil: detailedParametersServices?.provider ? detailedParametersServices?.provider?.phoneNumberOne : "Información no disponible",
                                direccion: detailedParametersServices?.provider ? detailedParametersServices?.provider?.address : "Información no disponible",
                                municipio: detailedParametersServices?.province ? detailedParametersServices?.province?.name : "Información no disponible",
                                email: detailedParametersServices?.provider ? detailedParametersServices?.provider?.email : "Información no disponible",
                            },
                        },
                        {
                            type: "object",
                            title: 'Información del Paciente Atendido',
                            columns: contentDataAcquirer,
                            hasContent: true,
                            content: {
                                tipoDocumento: detailedParametersServices?.userHealthInformation?.identificationType || "Información no disponible",
                                nroDocumento: detailedParametersServices?.userHealthInformation?.identificationNumber || "Información no disponible",
                                tipoUsuario: detailedParametersServices?.userHealthInformation?.userType || "Información no disponible",
                                primerApellido: detailedParametersServices?.userHealthInformation?.firstLastName || "Información no disponible",
                                segundoApellido: detailedParametersServices?.userHealthInformation?.secondLastName || "Información no disponible",
                                primerNombre: detailedParametersServices?.userHealthInformation?.firstName || "Información no disponible",
                                segundoNombre: detailedParametersServices?.userHealthInformation?.middleName || "Información no disponible",
                                edad: detailedParametersServices?.userHealthInformation?.age || "Información no disponible",
                                tipoEdad: detailedParametersServices?.userHealthInformation?.ageUnit || "Información no disponible",
                                sexo: detailedParametersServices?.userHealthInformation?.gender || "Información no disponible",
                                departamento: detailedParametersServices?.userHealthInformation?.province || "Información no disponible",
                                municipio: detailedParametersServices?.userHealthInformation?.city || "Información no disponible",
                                zona: detailedParametersServices?.userHealthInformation?.residenceArea || "Información no disponible",
                            }
                        },
                        {
                            type: "array",
                            title: 'Ítems Facturados',
                            columns: contentInvoicedItems,
                            hasContent: true,
                            content: detailedParametersServices?.edocumentLine?.map((doc: any)=> {
                                return {
                                    lineQuantity: doc.lineQuantity || "Información no disponible",
                                    itemCode: doc.itemCode || "Información no disponible",
                                    itemDescription: doc.itemDescription || "Información no disponible",
                                    itemPriceValue: doc.itemPriceValue || "Información no disponible",
                                    lineTotalValue: doc.lineTotalValue || "Información no disponible",
                                }
                            }),
                        },
                        {
                            type: "object",
                            title: 'Totales Facturados',
                            columns: contentDataTotalRows,
                            hasContent: true,
                            content: {
                                valueInvoice: detailedParametersServices?.totalValue || "0",
                                valueNoteCredite: detailedParametersServices?.creditTotalValue || "0",
                                valueNoteDebite: detailedParametersServices?.debitTotalValue|| "0",
                                totalAutorizationsPayment: detailedParametersServices?.paymentAuthorizationTotalValue || "0",
                                totalNotAutorizationsPayment: detailedParametersServices?.totalBalance || "0",
                            }
                        },
                        {
                            type: "array",
                            title: 'Soportes de la Prestación del Servicio',
                            columns: contentAttachmentPdf,
                            hasContent: true,
                            content:detailedParametersServices?.edocumentAttachment?.map((doc: any)=> {
                                const supportName = doc.support?.name?.replace(" (Obligatorio)", "") ?? '-';
                                return {
                                    support: supportName || "no cargado",
                                    contentType: doc.contentType?.split("/")[1] ?? "-",
                                    createdAt: doc.createdAt ? dayjs(doc?.createdAt).format('DD MMMM YYYY') : "-",
                                    status: doc.stateName || "Información no disponible",
                                    createdBy: doc.createdBy || "Información no disponible",
                                }
                            })
                        },
                        {
                            type: "object",
                            title: 'RIPS Información del CUV',
                            hasContent: !_.isEmpty(detailedParametersServices.ripsOutput?.ripsCUV),
                            columns: contentTableRowRipsCUV,
                            content: {
                                processId: detailedParametersServices.ripsOutput?.ripsCUV?.processId ?? "-",
                                invoiceNumber: detailedParametersServices.ripsOutput?.ripsCUV?.invoiceNumber ?? "-",
                                dateCuv: detailedParametersServices.ripsOutput?.ripsCUV?.dateCuv ?? "-",
                                timeCuv: detailedParametersServices.ripsOutput?.ripsCUV?.timeCuv ?? "-",
                                processState: detailedParametersServices.ripsOutput?.ripsCUV?.processState ?? "-",
                                cuv: detailedParametersServices.ripsOutput?.ripsCUV?.cuv ?? "-",
                            }
                        },
                        {
                            type: "object",
                            title: 'RIPS Información de Usuarios',
                            hasContent: !_.isEmpty(detailedParametersServices.ripsOutput?.ripsReportUS),
                            columns: contentTableRowRipsUS,
                            content: {
                                identificationType: detailedParametersServices.ripsOutput.ripsReportUS?.identificationType ?? "-",
                                identificationNumber: detailedParametersServices.ripsOutput.ripsReportUS?.identificationNumber ?? "-",
                                userType: detailedParametersServices.ripsOutput.ripsReportUS?.userType ?? "-",
                                firstLastName: detailedParametersServices.ripsOutput.ripsReportUS?.firstLastName ?? "-",
                                secondLastName: detailedParametersServices.ripsOutput.ripsReportUS?.secondLastName ?? "-",
                                firstName: detailedParametersServices.ripsOutput.ripsReportUS?.firstName ?? "-",
                                middleName: detailedParametersServices.ripsOutput.ripsReportUS?.middleName ?? "-",
                                age: detailedParametersServices.ripsOutput.ripsReportUS?.age ?? "-",
                                ageUnit: detailedParametersServices.ripsOutput.ripsReportUS?.ageUnit ?? "-",
                                gender: detailedParametersServices.ripsOutput.ripsReportUS?.gender ?? "-",
                                province: detailedParametersServices.ripsOutput.ripsReportUS?.province ?? "-",
                                city: detailedParametersServices.ripsOutput.ripsReportUS?.city ?? "-",
                                residenceArea: detailedParametersServices.ripsOutput.ripsReportUS?.residenceArea ?? "-",
                                birthDate: detailedParametersServices.ripsOutput.ripsReportUS?.birthDate ?? "-",
                                country: detailedParametersServices.ripsOutput.ripsReportUS?.country ?? "-",
                                inability: detailedParametersServices.ripsOutput.ripsReportUS?.inability ?? "-",
                                countryResidenceCode: detailedParametersServices.ripsOutput.ripsReportUS?.countryResidenceCode ?? "-",
                              },
                        },
                        {
                            type: "object",
                            title: 'RIPS Información de Factura',
                            hasContent: !_.isEmpty(detailedParametersServices.ripsOutput?.ripsReportAF),
                            columns: contentTableRowRipsAF,
                            content: {
                                healthServiceProviderCode: detailedParametersServices.ripsOutput.ripsReportAF?.healthServiceProviderCode ?? "-",
                                healthServiceProviderName: detailedParametersServices.ripsOutput.ripsReportAF?.healthServiceProviderName ?? "-",
                                identificationType: detailedParametersServices.ripsOutput.ripsReportAF?.identificationType ?? "-",
                                identificationNumber: detailedParametersServices.ripsOutput.ripsReportAF?.identificationNumber ?? "-",
                                invoiceNumber: detailedParametersServices.ripsOutput.ripsReportAF?.invoiceNumber ?? "-",
                                invoiceIssuedAt: detailedParametersServices.ripsOutput.ripsReportAF?.invoiceIssuedAt ?? "-",
                                attentionPeriodStartedAt: detailedParametersServices.ripsOutput.ripsReportAF?.startDate ?? '',
                                attentionPeriodEndedAt: detailedParametersServices.ripsOutput.ripsReportAF?.endDate ?? '',
                                epsCode: detailedParametersServices.ripsOutput.ripsReportAF?.epsCode ?? "-",
                                epsName: detailedParametersServices.ripsOutput.ripsReportAF?.epsName ?? "-",
                                contractNumber: detailedParametersServices.ripsOutput.ripsReportAF?.contractNumber ?? "-",
                                benefitsPlan: detailedParametersServices.ripsOutput.ripsReportAF?.benefitsPlan ?? "-",
                                insurancePolicyNumber: detailedParametersServices.ripsOutput.ripsReportAF?.insurancePolicyNumber ?? "-",
                                sharedFeeValue: detailedParametersServices.ripsOutput.ripsReportAF?.sharedFeeValue ?? "-",
                                commissionValue: detailedParametersServices.ripsOutput.ripsReportAF?.commissionValue ??"-",
                                discountsValue: detailedParametersServices.ripsOutput.ripsReportAF?.discountsValue ?? "-",
                                totalValue: detailedParametersServices.ripsOutput.ripsReportAF?.totalValue ?? "-",
                            },
                        },
                        {
                            type: "array",
                            title: 'RIPS Información de Consultas',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAC && !!detailedParametersServices.ripsOutput?.ripsReportAC[0].id,
                            columns: contentTableRowRipsACPdf,
                            content: detailedParametersServices.ripsOutput?.ripsReportAC?.map((item: ripsReportAC, index:number) => {
                                return {
                                  consultationCodeName: item.consultationCodeName?.name ?? "-",
                                  consultationPurpose: item.consultationPurpose ?? "-",
                                  externalCause: item.externalCause ?? "-",
                                  primaryDiagnosis: item.primaryDiagnosis ?? "-",
                                  primaryDiagnosisType: item.primaryDiagnosisType ?? "-",
                                  consultationValue: item.consultationValue ?? "-",
                                  sharedFeeValue: item.sharedFeeValue ?? "-",
                                  totalValue: item.totalValue ?? "-",
                                  moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",
                                }
                            })
                        },
                        {
                            type: "object",
                            title: 'RIPS Información de Urgencias',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAU?.id,
                            columns: contentTableRowRipsAU,
                            content: {
                                invoiceNumber: detailedParametersServices.ripsOutput?.ripsReportAU?.invoiceNumber?? "-",
                                healthServiceProviderCode: detailedParametersServices.ripsOutput?.ripsReportAU?.healthServiceProviderCode ?? "-",
                                identificationType: detailedParametersServices.ripsOutput?.ripsReportAU?.identificationType ?? "-",
                                identificationNumber: detailedParametersServices.ripsOutput?.ripsReportAU?.identificationNumber ?? "-",
                                admissionDate: detailedParametersServices.ripsOutput?.ripsReportAU?.admissionDate ?? "-",
                                admissionTime: detailedParametersServices.ripsOutput?.ripsReportAU?.admissionTime ?? "-",
                                authorizationNumber: detailedParametersServices.ripsOutput?.ripsReportAU?.authorizationNumber ?? "-",
                                externalCause: detailedParametersServices.ripsOutput?.ripsReportAU?.externalCause ?? "-",
                                egressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAU?.egressDiagnosis ?? "-",
                                firstRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAU?.firstRelatedEgressDiagnosis ?? "-",
                                secondRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAU?.secondRelatedEgressDiagnosis ?? "-",
                                thirdRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAU?.thirdRelatedEgressDiagnosis ?? "-",
                                egressDestination: detailedParametersServices.ripsOutput?.ripsReportAU?.egressDestination ?? "-",
                                egressVitalState: detailedParametersServices.ripsOutput?.ripsReportAU?.egressVitalState ?? "-",
                                deathCause: detailedParametersServices.ripsOutput?.ripsReportAU?.deathCause ?? "-",
                                egressDate: detailedParametersServices.ripsOutput?.ripsReportAU?.egressDate ?? "-",
                                egressTime: detailedParametersServices.ripsOutput?.ripsReportAU?.egressTime ?? "-",
                                admissionDateTime: detailedParametersServices.ripsOutput?.ripsReportAU?.admissionDateTime ?? "-",
                                entranceDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAU?.entranceDiagnosis ?? "-",
                                egressDateTime: detailedParametersServices.ripsOutput?.ripsReportAU?.egressDateTime ?? "-",
                            }
                        },
                        {
                            type: "object",
                            title: 'RIPS Información de Hospitalización',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAH?.id,
                            columns: contentTableRowRipsAH,
                            content: {
                                invoiceNumber: detailedParametersServices.ripsOutput?.ripsReportAH?.invoiceNumber ?? "-",
                                healthServiceProviderCode: detailedParametersServices.ripsOutput?.ripsReportAH?.healthServiceProviderCode ?? "-",
                                identificationType: detailedParametersServices.ripsOutput?.ripsReportAH?.identificationType ?? "-",
                                identificationNumber: detailedParametersServices.ripsOutput?.ripsReportAH?.identificationNumber ?? "-",
                                admissionRoad: detailedParametersServices.ripsOutput?.ripsReportAH?.admissionRoad ?? "-",
                                admissionDate: detailedParametersServices.ripsOutput?.ripsReportAH?.admissionDate ?? "-",
                                admissionTime: detailedParametersServices.ripsOutput?.ripsReportAH?.admissionTime ?? "-",
                                authorizationNumber: detailedParametersServices.ripsOutput?.ripsReportAH?.authorizationNumber ?? "-",
                                externalCause: detailedParametersServices.ripsOutput?.ripsReportAH?.externalCause ?? "-",
                                primaryAdmissionDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.primaryAdmissionDiagnosis ?? "-",
                                primaryEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.primaryEgressDiagnosis ?? "-",
                                firstRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.firstRelatedEgressDiagnosis ?? "-",
                                secondRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.secondRelatedEgressDiagnosis ?? "-",
                                thirdRelatedEgressDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.thirdRelatedEgressDiagnosis ?? "-",
                                complicationDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.complicationDiagnosis ?? "-",
                                egressVitalState: detailedParametersServices.ripsOutput?.ripsReportAH?.egressVitalState ?? "-",
                                deathCauseDiagnosis: detailedParametersServices.ripsOutput?.ripsReportAH?.deathCauseDiagnosis ?? "-",
                                egressDate: detailedParametersServices.ripsOutput?.ripsReportAH?.egressDate ?? "-",
                                egressTime: detailedParametersServices.ripsOutput?.ripsReportAH?.egressTime ?? "-",
                                attentionStartDate: detailedParametersServices.ripsOutput?.ripsReportAH?.attentionStartDate ?? "-",
                                egressDateTime: detailedParametersServices.ripsOutput?.ripsReportAH?.egressDateTime ?? "-",
                            },
                        },
                        {
                            type: "array",
                            title: 'RIPS Información de Procedimientos',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAP && !!detailedParametersServices.ripsOutput?.ripsReportAP[0].id,
                            columns: contentTableRowRipsAP,
                            content: detailedParametersServices.ripsOutput?.ripsReportAP?.map((item: ripsReportAP, index:number) => {
                                return {
                                    id:index,
                                    healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
                                    identificationType: item.identificationType ?? "-",
                                    identificationNumber: item.identificationNumber ?? "-",
                                    procedureDate: item.procedureDate ?? "-",
                                    authorizationNumber: item.authorizationNumber ?? "-",
                                    procedureCode :item.procedureCode ?? "-",
                                    procedureCodeName: item.procedureCodeName?.name ?? "-",
                                    procedureContext: item.procedureContext ?? "-",
                                    procedurePurpose: item.procedurePurpose ?? "-",
                                    attendantStaff: item.attendantStaff ?? "-",
                                    primaryDiagnosis: item.primaryDiagnosis ?? "-",
                                    relatedDiagnosis: item.relatedDiagnosis ?? "-",
                                    complication: item.complication ?? "-",
                                    procedureExecutionMethod: item.procedureExecutionMethod ?? "-",
                                    procedureValue: item.procedureValue ?? "-",
                                    attentionStartDate: item.attentionStartDate ?? "-",
                                    mipresId: item.mipresId ?? "-",
                                    admissionRoad: item.admissionRoad ?? "-",
                                    serviceGroupModalityTecsal: item.serviceGroupModalityTecsal ?? "-",
                                    serviceGroup: item.serviceGroup ?? "-",
                                    serviceCod: item.serviceCod ?? "-",
                                    collectionConcept: item.collectionConcept ?? "-",
                                    sharedFeeValue: item.sharedFeeValue ?? "-",
                                    moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",
                                  }
                            })
                        },
                        {
                            type: "array",
                            title: 'RIPS Información de Otros Servicios',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAT && !!detailedParametersServices.ripsOutput?.ripsReportAT[0].id,
                            columns: contentTableRowRipsATPdf,
                            content: detailedParametersServices.ripsOutput?.ripsReportAT?.map((item: ripsReportAT, index:number) => {
                                return {
                                    serviceType: item.serviceType ?? "-",
                                    serviceCode: item.serviceCode ?? "-",
                                    serviceName: item.serviceName ?? "-",
                                    quantity: item.quantity ?? "-",
                                    unitValue: item.unitValue ?? "-",
                                    totalValue: item.totalValue ?? "-",
                                  }
                            })
                        },
                        {
                            type: "array",
                            title: 'RIPS Información de Medicamentos',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAM && !!detailedParametersServices.ripsOutput?.ripsReportAM[0].id,
                            columns: contentTableRowRipsAMPdf,
                            content: detailedParametersServices.ripsOutput?.ripsReportAM?.map((item: ripsReportAM, index:number) => {
                                return {
                                    medicineCode: item.medicineCode ?? "-",
                                    medicineType: item.medicineType ?? "-",
                                    activePrinciple: item.activePrinciple ?? "-",
                                    pharmaceuticalPresentation: item.pharmaceuticalPresentation ?? "-",
                                    measurementUnit: item.measurementUnit ?? "-",
                                    amountOfUnits: item.amountOfUnits ?? "-",
                                    unitValue: item.unitValue ?? "-",
                                    totalValue: item.totalValue ?? "-",
                                  }
                            })
                        },
                        {
                            type: "array",
                            title: 'RIPS Información de recién Nacidos',
                            hasContent: !!detailedParametersServices.ripsOutput?.ripsReportAN && !!detailedParametersServices.ripsOutput?.ripsReportAN[0].id,
                            columns: contentTableRowRipsANPdf,
                            content: detailedParametersServices.ripsOutput?.ripsReportAN?.map((item: ripsReportAN, index:number) => {
                                return {
                                    identificationType: item.identificationType ?? "-",
                                    identificationNumber: item.identificationNumber ?? "-",
                                    birthDate: item.birthDateTime ?? "-",
                                    pregnancyStatus: item.pregnancyStatus ?? "-",
                                    birthControl: item.birthControl ?? "-",
                                    gender: item.gender ?? "-",
                                    weight: item.weight ?? "-",
                                    newBornDiagnosis: item.newBornDiagnosis ?? "-",
                                  }
                            })
                        },
                        {
                            type: "array",
                            title: 'Documentos Electrónicos',
                            columns: contentERelatedPdf,
                            hasContent: !_.isEmpty(detailedParametersServices?.relatedEDocument[0]),
                            content:detailedParametersServices?.relatedEDocument?.map((doc: any)=> {
                                return {
                                    support: doc.documentNumber || "Información no disponible",
                                    contentType: doc.type.description || "Información no disponible",
                                    createdAt: doc.issuedAt ? dayjs(doc?.issuedAt).format('DD MMMM YYYY') : "Información no disponible",
                                    status: doc.state.name || "Información no disponible",
                                    value: doc.totalValue || "Información no disponible",
                                    cude: doc.uniqueCodeElectronicBilling || "Información no disponible",
                                }
                            })
                        },
                        {
                            type: "array",
                            title: 'Detalle de Trazabilidad de Factura',
                            columns: contentTableRowDetailed,
                            hasContent: !_.isEmpty(detailedParametersServices?.eventHistory[0]),
                            content:detailedParametersServices?.eventHistory?.map((doc: any)=> {
                                return {
                                    id: doc.id || "Información no disponible",
                                    idFecha: doc.createdAt ? dayjs(doc?.createdAt).format('DD MMMM YYYY') : "Información no disponible",
                                    idAccion: doc?.eventDetails?.Acción ?? doc.state.name,
                                    idDescripcion: Object.values(doc?.eventDetails ?? []).join(', ').trim() ?? '-',
                                    idUsuario: doc?.createdBy ?? 'Ejecución Automatica del Sistema',
                                }
                            })
                        },
                        {
                            title: 'Detalle de Auditoría de Factura',
                            columns: contentDataAuditDetailsPdf,
                            hasContent: !_.isEmpty(detailedParametersServices.auditDocument),
                            content: {
                                userInfoText: detailedParametersServices?.auditDocument?.userInfoText,
                                userInfoQuestion: detailedParametersServices?.auditDocument?.userInfoQuestion,
                                validateRightText: detailedParametersServices?.auditDocument?.validateRightText,
                                validateRightQuestion: detailedParametersServices?.auditDocument?.validateRightQuestion,
                                adminInfoText: detailedParametersServices?.auditDocument?.adminInfoText,
                                adminInfoQuestion: detailedParametersServices?.auditDocument?.adminInfoQuestion,
                                clinicHistoryText: detailedParametersServices?.auditDocument?.clinicHistoryText ,
                                clinicHistoryQuestion: detailedParametersServices?.auditDocument?.clinicHistoryQuestion,
                                medicalAttentionText: detailedParametersServices?.auditDocument?.medicalAttentionText,
                                medicalAttentionQuestion: detailedParametersServices?.auditDocument?.medicalAttentionQuestion,
                                processText: detailedParametersServices?.auditDocument?.processText,
                                processQuestion: detailedParametersServices?.auditDocument?.processQuestion,
                                medicationsText: detailedParametersServices?.auditDocument?.medicationsText,
                                medicationsQuestion: detailedParametersServices?.auditDocument?.medicationsQuestion,
                                otherServicesText: detailedParametersServices?.auditDocument?.otherServicesText,
                                otherServicesQuestion: detailedParametersServices?.auditDocument?.otherServicesQuestion,
                            }
                        },
                        {
                            type: "object",
                            title: 'Detalle de Glosas',
                            columns: contentDataGlossas,
                            hasContent: !_.isEmpty(detailedParametersServices.glossesOutput?.glossesEDocuments[0]),
                            content: {
                                dateGlossa: detailedParametersServices.glossesOutput?.edocumentPaymentAuthorization?.createdAt ? dayjs(new Date(detailedParametersServices.glossesOutput?.edocumentPaymentAuthorization?.createdAt)).format('DD MMMM YYYY HH:mm') : "",
                                totalGlossa: detailedParametersServices.glossesOutput?.glossesTotalValue || "0",
                                auditGlossa: detailedParametersServices.glossesOutput?.edocumentPaymentAuthorization?.createdBy || "Información no disponible",
                                justification: detailedParametersServices.glossesOutput?.edocumentPaymentAuthorization?.glossesGeneralDetail ? removeHTMLTagsFromText(detailedParametersServices.glossesOutput?.edocumentPaymentAuthorization?.glossesGeneralDetail) : "Información no disponible",
                            }
                        },
                        {
                            type: "array",
                            title: 'Listado de Glosas',
                            columns: contentDataGridGlosasPdf,
                            hasContent: !_.isEmpty(detailedParametersServices.glossesOutput?.glossesEDocuments[0]),
                            content:detailedParametersServices?.glossesOutput?.glossesEDocuments?.map((doc: any)=> {
                                return {
                                    nameGlosa: doc.gloss?.name || "Información no disponible",
                                    valueGlosa: doc.valueGloss || "0",
                                    statusGlosa: doc.state?.description || "Información no disponible",
                                    valueAccepted: doc.valueAccept || "0",
                                    valueSupported: doc.valueSustained || "0",
                                    valueNotAccepted: doc.valueNotAccept || "0",
                                    justification: doc.justification || "0",
                                }
                            })
                        },
                        {
                            type: "object",
                            title: 'Detalle de Resultado de Auditoría',
                            columns: contentDataAudit,
                            hasContent: !_.isEmpty(detailedParametersServices.glossesOutput?.glossesEDocuments[0]),
                            content: {
                                valueAccepted: detailedParametersServices.acceptedConsolidatedValue || "0",
                                valueSustained: detailedParametersServices.sustainedConsolidatedValue || "0",
                                valueNotAccepted: detailedParametersServices.consolidatedInNoAgreementValue || "0",
                                dateAudit: detailedParametersServices.acceptanceDateConsolidatedValue ? dayjs(new Date(detailedParametersServices.acceptanceDateConsolidatedValue)).format('DD MMMM YYYY HH:mm') : "Información no disponible",
                                justification: detailedParametersServices.edocumentAuditConsolidate?.auditResultDetail ? removeHTMLTagsFromText(detailedParametersServices.edocumentAuditConsolidate?.auditResultDetail) : "Información no disponible",
                            }
                        },
                        {
                            type: "object",
                            title: 'Detalle Auditoria de Autorización de Pagos',
                            columns: contentAutorizationAudit,
                            hasContent: !_.isEmpty(detailedParametersServices.glossesOutput?.glossesEDocuments[0]),
                            content: {
                                totalValue: detailedParametersServices.totalValue || "0",
                                totalCredits: detailedParametersServices.creditTotalValue || "0",
                                totalDebits: detailedParametersServices.debitTotalValue || "0",
                                totalGlosses: detailedParametersServices.glossesTotalValue || "0",
                                paymentInitial: detailedParametersServices.paymentAuthorizationInitialValue || "0",
                                valueAccepted: detailedParametersServices.acceptedConsolidatedValue || "0",
                                valueSustained: detailedParametersServices.sustainedConsolidatedValue || "0",
                                valueNotAccepted: detailedParametersServices.consolidatedInNoAgreementValue || "0",
                                newValueAutorizated: detailedParametersServices.sustainedConsolidatedValue || "0",
                            }
                        },
                        {
                            type: "array",
                            title: 'Autorización de Pagos',
                            columns: contentGridPaymentAuthorizationPdf,
                            hasContent: !_.isEmpty(detailedParametersServices?.paymentAuthorization[0]),
                            content:detailedParametersServices?.paymentAuthorization?.map((doc: any)=> {
                                return {
                                    id: doc.consecutive || "Información no disponible",
                                    dateAuthorization: doc.createdAt ? dayjs(doc?.createdAt).format('DD MMMM YYYY') : "Información no disponible",
                                    valueAuthorization: doc.authorizationValue || "Información no disponible",
                                    auditor: doc.createdBy || "Información no disponible",
                                    line: doc.paymentLine?.description || "Información no disponible",
                                    state: doc.state?.name || "Información no disponible",
                                }
                            })
                        },
                        {
                            type: "array",
                            title: 'Comentarios',
                            columns: contentCommentsnPdf,
                            hasContent: !_.isEmpty(detailedParametersServices?.edocumentComment[0]),
                            content:detailedParametersServices?.edocumentComment?.map((doc: any)=> {
                                return {
                                    comment: doc.comment ? removeHTMLTagsFromText(doc.comment) : "Información no disponible",
                                    createdAt: doc.createdAt ? dayjs(doc?.createdAt).format('DD MMMM YYYY') : "Información no disponible",
                                    createdBy: doc.createdBy || "Información no disponible",
                                }
                            })
                        },
                    ]
                },
            )
        )
    }

    useEffect(() => {
        transformDataDocumentPdf(doc);
    }, [])
    
    return {
        bill,
        contentDocument,
    }
}
