import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Dispatch } from "shared/store/store";
import { cleanLocalStorage } from "shared/utils";

export const useNavigateToCustom = () => {
    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();

    const navigateHome = () => {
		navigate('/');
	}

    const navigateToInvoices = () => {
        cleanLocalStorage('searchHistoryElectronicDocument')
        cleanLocalStorage('searchHistoryAutorizationPayments')
		dispatch.menuDrawer.changeSelectedIndex(1);
        navigate('/consulta_documentos_electronicos');
		
        setTimeout(() => {
            dispatch.electronicDocuments.cleanState();
        });
	}

    return {
        navigateToInvoices,
        navigateHome
    }
}
