import logoGobernacion from './images/logo-gobernacion.png';
import logoGobernacion2024 from './images/logo-gobernacion-2024.png';
import logoSSSA from './images/LogoSSSA.png';
import imagenFondoLogin from './images/ImagenFondo.png';
import logoFacturacion from './images/logo-facturacion.png';
import logoTrazabilidad from './images/logo-trazabilidad.png';
import iconoOrdenar from './images/icono-ordenar.svg';
import iconoOrdenar2 from './images/icono-ordenar2.png';
import iconoSearch from './images/icono-search.svg';
import iconoEditar from './images/icono-editar.svg';
import iconoEliminar from './images/icono-eliminar.svg';
import iconoDefault from './images/icono-default.svg';
import iconoNotificaciones from './images/icono-notificaciones.svg';
import iconoHome from './images/icono-home.svg';
import iconoFacturas from './images/icono-facturas.svg';
import iconoOpciones from './images/icono-options.svg';
import iconoDocumento from './images/icono-documento.svg';
import iconoRips from './images/icono-rips.svg';
import iconoBanco from './images/icono-banco.svg';
import iconoHospital from './images/icono-hospital.svg';
import iconoDescarga from './images/icono-descarga.svg';
import iconoCheck from './images/icono-check.svg';
import iconoMonedas from './images/icono-monedas.svg';
import iconoManos from './images/icono-manos.svg';
import iconoNulo from './images/icono-nulo.svg';
import iconoPago from './images/icono-pago.svg';
import fondoHome from './images/fondo-home.png';
import circuloFondo from './images/icono-circulo-fondo.svg';
import iconoBorrar from './images/icono-borrar.svg';
import iconoLectura from './images/icono-lectura.svg';
import iconoGlosanoacuerdo from './images/icono-glosanoacuerdo.png';
import iconoClasificado from './images/icono-clasificado.png';
import iconoResponder from './images/icono-responder.png';
import iconoResumen from './images/icono-resumen.png';
import iconoAsignarUsuario from './images/icono-asignar-usuario.svg';
import iconoDescargar from './images/icono-descargar.svg';
import iconoVer from './images/icono-ver.svg';
import iconoCerrar from './images/icono-cerrar.svg'
import iconoContrato from './images/icono-contrato.png'
import iconoContractExecution from './images/contractExecution.png'
import iconoMoney from './images/money.svg'
import iconoNumber from './images/number.svg'
import iconoPercent from './images/percent.svg'
import iconoInfoContact from './images/Icono-info-contact.png';
import iconoPDF from './images/icono-pdf.svg';

const iconos = {
    logoGobernacion,
    logoSSSA,
    imagenFondoLogin,
    logoFacturacion,
    logoTrazabilidad,
    iconoOrdenar,
    iconoOrdenar2,
    iconoSearch,
    iconoEditar,
    iconoEliminar,
    iconoDefault,
    iconoNotificaciones,
    iconoHome,
    iconoFacturas,
    iconoOpciones,
    iconoDocumento,
    iconoRips,
    iconoBanco,
    iconoHospital,
    iconoDescarga,
    iconoCheck,
    iconoMonedas,
    iconoManos,
    iconoNulo,
    iconoPago,
    iconoBorrar,
    iconoLectura,
    iconoGlosanoacuerdo,
    iconoClasificado,
    iconoResponder,
    iconoResumen,
    circuloFondo,
    fondoHome, 
    iconoAsignarUsuario,
    iconoDescargar,
    iconoVer,
    iconoCerrar,
    iconoContrato,
    iconoContractExecution,
    iconoMoney,
    iconoNumber,    
    iconoPercent,
    iconoInfoContact,
    iconoPDF,
    logoGobernacion2024
}

export default iconos