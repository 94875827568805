interface RouterParams {
    idDocument?: string;
    [key: string]: string | undefined;
}
  
type BreadcrumbFunction = (params: RouterParams) => string;

export interface BreadcrumbNameMap {
    [key: string]: string | BreadcrumbFunction;
}

export const breadcrumbNameElectronicDocumentsMap: BreadcrumbNameMap = {
    '/consulta_documentos_electronicos': 'Consulta Documentos Electrónicos',
    '/consulta_documentos_electronicos/detalle_documento/:value': 'Detalle Documento',
    '/consulta_documentos_electronicos/detalle_documento/:value/auditar_glosas': 'Auditar Glosas',
    '/consulta_documentos_electronicos/detalle_documento/:value/ver_detalle_contrato/:value2': 'Ver Detalle Contrato',
    '/consulta_documentos_electronicos/radicar_facturas/:vale': 'Radicar Facturas',
    '/reportes': 'Reportes',
    '/reportes/:value': 'Visualizar Reporte',
    '/autorizaciones': 'Autorizaciones',
    '/autorizaciones/detalle_documento/:value': 'Detalle Documento',
};