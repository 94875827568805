import React, { useEffect, useState } from 'react';
import Image from 'shared/assets/index';
import styles from './style/formReplyGlosses.module.scss';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { RichTextEditor, Spinner } from 'shared/components';
import { consolidateGloss, removeHTMLTagsFromText } from 'shared/utils';
import Swal from 'sweetalert2';
import { createRoot } from 'react-dom/client';
import { SwalAlertContent } from '../SwalAlertContent';
import { putData } from 'shared/api/services/putData';
import { GLOSSES } from 'shared/api';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { IFormConsolidateGlossas } from './interface/IFormConsolidateGlossas';

export const FormConsolidateGlossas = ({onCloseModal, dataSingleGlosa, billName, idDocument, callGetDetailedDocument, provider }: IFormConsolidateGlossas) => {
    const dispatch = useDispatch<Dispatch>();
    const [valueRadioButton, setValueRadioButton] = useState<string | null>(null);
    const [totalCharacters, setTotalCharacters] = useState(0);
    const [richTextValue, setRichTextValue] = useState<string>(' ');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string | null>(null);

    useEffect(() => {
        setValueRadioButton(dataSingleGlosa?.glossSupport?.toString() || null)
        setRichTextValue(dataSingleGlosa.answerConsolited || ' ')
        dataSingleGlosa?.answerConsolited && setTotalCharacters( removeHTMLTagsFromText(dataSingleGlosa?.answerConsolited).length )

        return () => {
            setValueRadioButton(null)
            setRichTextValue('')
            setTotalCharacters(0)
        }
  
    }, [dataSingleGlosa])   

    const isReadOnly = ()=> dataSingleGlosa.statusGlosa.code === '8';
    
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueRadioButton(event.target.value);
        setErrors(null);
    };
    
    const handleRichTextChange = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);
        setTotalCharacters(cleanText.length);
        setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    };
    
    const confirmConsolidateGlossa = async () => {
        setIsLoading(true);
        try {
          const data = {
            answerConsolited: richTextValue,
            glossSupport: valueRadioButton
          };
          await putData(`${GLOSSES}/${dataSingleGlosa.id}/consolidate/electronic-documents/${idDocument}`, data);
            dispatch.consolidateGlosas.getlistGlosasAsync(idDocument);
            callGetDetailedDocument(idDocument);
            onCloseModal();
            Swal.clickCancel();
        } catch (error) {
          console.error('Error consolidating glossa:', error);
        } finally {
          setIsLoading(false);
          dispatch.messageModal.cleanStateMessage()
        }
    };
    
    const validateForm = () => {
        const cleanText = removeHTMLTagsFromText(richTextValue);
        
        if (valueRadioButton === null) {
            setErrors("Es obligatorio seleccionar una opción");
            return false;
        }

        if (cleanText.length < 200 || cleanText.length > 5000) {
            dispatch.messageModal.setMessageModal({ hide: true });
            Swal.fire({
                title: `El campo consolidar debe tener mínimo 200 caracteres y máximo 5000`,
                icon: "error",
                timer: 5000,
            }).then(() => dispatch.messageModal.setMessageModal({ hide: false }));
            
            return false;
        }

        return true;
    };
    
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        if (!validateForm()) return;

        const modalComponent = document.createElement("div");
        createRoot(modalComponent).render(
            <SwalAlertContent
                description={`¿Está seguro que desea consolidar glosa ${dataSingleGlosa.nameGlosa} de la factura ${billName} ?`}
                highlightedText=''
                useCheck={true}
                checkText='Confirmo que deseo consolidar Glosas'
                onCancel={() => {
                    Swal.clickCancel();
                    dispatch.messageModal.setMessageModal({ hide: false });
                }}
                onConfirm={confirmConsolidateGlossa}
                textBtn='Responder'
            />
        );

        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
        }).then(() => dispatch.messageModal.setMessageModal({ hide: false }));
    };
    
    return (
    <form className={styles.containerForm} onSubmit={handleSubmit} data-testid='form-consolidate'>
        <Grid container className={styles.contentForm}>
            <div className={styles.containerTitleCard}>
                <img src={Image.iconoResponder} alt="" />
                <Typography className={styles.titleCard}>Consolidar Glosa</Typography>
            </div>
            <Typography 
                className={styles.contentInformation} 
                dangerouslySetInnerHTML={{ __html: consolidateGloss }} 
            />

            <Grid container direction="row">
                <Grid item xs={12} className={styles.contentItem}>
                    <FormControl className={styles.containerradioGroup}>
                    <FormLabel id="consolidate-group-label">
                        ¿El prestador {provider.name} logró sustentar la glosa {dataSingleGlosa.nameGlosa} de la factura {billName} ?
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="consolidate-group-label"
                        name="consolidate-group-label"
                        className={styles.radioGroup}
                        value={valueRadioButton}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="true" control={<Radio />} data-testid='is_paid_si' label="SI" disabled={isReadOnly()} />
                        <FormControlLabel value="false" control={<Radio />} data-testid='is_paid_no'  label="NO" disabled={isReadOnly()} />
                    </RadioGroup>
                    </FormControl>
                    {errors && (
                        <Grid item xs={12}>
                            <span className={styles.textInputError} data-testid='error-radio'>{errors}</span>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} className={styles.contentItem}>
                <Box className={styles.textRichDetailsContainer}>
                    <Typography variant='h6' className={styles.detailsLabel}>
                        Consolidar Glosa - Obligatorio
                    </Typography>
                    <Box className={styles.textRichContainer}>
                    <RichTextEditor
                        onChange={handleRichTextChange}
                        readOnly={isReadOnly()}
                        value={richTextValue}
                    />
                    </Box>
                    <span className={styles.details}>{totalCharacters}/{5000}</span>
                </Box>
                </Grid>
                {
                    !isReadOnly() && (
                        <Box className={styles.buttonsContainer}>
                            <Button
                                variant='contained'
                                className={styles.confirmButton}
                                data-testid='reply_glosses'
                                type='submit'
                            >
                                Consolidar
                            </Button>
                            <Button
                                variant='contained'
                                className={styles.cancelButton}
                                data-testid='cancel_reply_glosses'
                                onClick={onCloseModal}
                            >
                                Cancelar
                            </Button>
                        </Box>
                    )
                }
            </Grid>
        </Grid>
        {isLoading && <Spinner />}
    </form>
    );
};
